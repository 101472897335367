import React, { useState } from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const Faq = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/faq-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>FAQ</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/knowledge-center">Knowledge Center</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/faq" className="active">
                      FAQ
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="our_other_links2">
                        <h3>Other Links</h3>
                        <ul>
                          <li>
                            <Link to="/new-service-connection-calculate">Calculate Energy Consumption</Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres">
                              Payment Options
                            </Link>
                          </li>
                          <li>
                            <Link to="/online-vendor-registration">
                              Vendor Registration
                            </Link>
                          </li>
                          <li>
                            <a href="#">Regulation &amp; Statutory Bodies</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div">
                      <legend>FAQ</legend>
                      <div id="accordion">
                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow9(!show9)}
                          >
                            <b>Meter Related</b>
                          </div>
                          {show9 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q. What should be done in case there is no
                                  supply in the meter ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. In case the nearby area is under Supply
                                Outage, you may wait until the supply is
                                restored. If there is no power even after the
                                supply is restored Consumer may contact at our
                                24X7 CALL CENTER - 0120 6226666. You may reach
                                out us through our WhatsApp Bot based services
                                on 0120-6226666 or Send SMS at 7840002288 (
                                #NOPOWER {"<Space>"} Consumer No.).
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What to do in case of meter is running
                                  fast/ excess billing?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Domestic Consumer: May please check the
                                internal wiring by any private electrician. If
                                no such issue persist may kindly contact 24X7
                                Call Centre No. 0120-6226666 or you may apply
                                thru our website by following below mentioned
                                link{" "}
                                <a href="https://iwebapps.noidapower.com:8032/MTRCHPMT/MeterTesting_Reg.aspx">
                                  https://iwebapps.noidapower.com:8032/MTRCHPMT/MeterTesting_Reg.aspx
                                </a>{" "}
                                or may visit Customer Care Office at KP-I and
                                and Techzone-4 to register request for Meter
                                Testing. Demand note for meter testing fees will
                                be generated as per UPERC guidelines.
                              </p>
                              <p className="card_cont">
                                Industrial &amp; Institutional Consumers : May
                                please check the Power Factor &amp; Neutral
                                disturbance issue to avoid any demand shoot-up
                                in billing. if persist may kindly contact 24X7
                                Call Centre No. 0120-6226666
                              </p>
                              <p className="card_cont">
                                You may reach out us through our WhatsApp Bot
                                based services on 0120-6226666 or Send SMS at
                                7840002288 ( #BILLDISPUTE {"<Space>"} Consumer
                                No.) or else you may also visit our Customer
                                Care office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                or{" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How to analysis correct meter consumption/
                                  abnormal consumption?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. In a normal house hold having 1 or 2 A/C’s
                                along with Geyser, Fridge etc., the average
                                units consumed may be between 500 to 1000 Units
                                per month depending upon actual use. If consumer
                                feel that the consumption is more as compared to
                                the usage and load, we request consumers to
                                please get their wiring checked by competent
                                electrician with respect to below mentioned
                                points:
                              </p>
                              <ul>
                                <li>
                                  To check any Earth Leakage in the internal
                                  wiring or not.
                                </li>
                                <li>
                                  In case of multiple connections in the
                                  building, whether your internal wiring has a
                                  separate -neutral wire and should not be mixed
                                  with other connections.
                                </li>
                                <li>
                                  Check if someone else is also using supply
                                  from your meter or not.
                                </li>
                              </ul>
                              <p className="card_cont">
                                However, it is to inform that, NPCL will not be
                                responsible for higher consumptions due to any
                                of the above mentioned reasons but taking action
                                by consumer will reduce the consumption. After
                                having checked all the above, If consumption is
                                still not reduced as per consumer’s expectation,
                                then s/he is having an option to get the meter
                                tested by contacting our helpline number
                                +91-0120-6226666 by applying thru our website by
                                clicking on below mentioned link{" "}
                                <a href="https://iwebapps.noidapower.com:8032/MTRCHPMT/MeterTesting_Reg.aspx">
                                  https://iwebapps.noidapower.com:8032/MTRCHPMT/MeterTesting_Reg.aspx
                                </a>{" "}
                                or may visit Customer Care Office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                or{" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday. and get the offline requests generated
                                for meter checking . Meter Testing fees will be
                                generated & added in subsequent bill as per
                                UPERC guidelines.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What are the various reasons of meter
                                  change?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. A Distribution Licensee may change the
                                meter from time to time for any of the following
                                reasons:
                              </p>
                              <ul>
                                <li>Version Upgrade</li>
                                <li>Meter is burnt</li>
                                <li>Meter is faulty</li>
                                <li>Meter is damaged</li>
                                <li>No display in the meter</li>
                                <li>Suspected to be tampered</li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. What are the timelines/Resolutions for
                                  Meter Testing, Fast, Slow, Burnt, Damaged,
                                  Stop, Faulty, Shifting?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Time period for compliances
                              </p>
                              <ul>
                                <li>
                                  <b>Meter Stop/Meter Damaged</b>: 15 working
                                  days for meter replacement.
                                </li>
                                <li>
                                  <b>Meter Slow/Fast</b>: 15 working days.
                                </li>
                                <li>
                                  <b>Meter Burnt</b>: 3 Working Days for
                                  replacement of the burnt meter.
                                </li>
                                <li>
                                  <b>Meter Shifting</b>: 7 Working Days as per
                                  site conditions.
                                </li>
                              </ul>

                              <p className="card_cont">
                                <span>
                                  Q. How to convert post-paid connection to
                                  prepaid connection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. To convert the category, consumer may visit
                                our Customer Care Office at {" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Consumer has to apply under category conversion
                                services by filling up an application form and submit
                                their ID copy at Knowledge Park-I, Techzone-IV, Sigma-IV
                                or Ecotech-2.  Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What are the security deposit charges for
                                  conversion from post-paid to pre-paid or for
                                  new connection installation in Pre-paid?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Security Deposit charges are not applicable
                                for Prepaid Meter. For conversion/New connection
                                prepaid Meter charges are to paid as per UPERC{" "}
                                <a href="../cost-data-book">cost data book</a>{" "}
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What is the meaning of downloading of
                                  parameter?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Various parameters measured and recorded by
                                the meter are finally downloaded for billing/
                                monitoring purpose. The downloading of parameter
                                means transferring the recorded data from meter
                                to the records of the company. Downloading can
                                be automatic (AMR) or using a gadget. However,
                                we can capture data / parameter by reading the
                                display recording on a notebook.
                              </p>
                              <p className="card_cont">
                                <span>Q. Who can apply for Prepaid Meter?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumers pertatining to below mentioned
                                categories can apply for prepaid meter:
                              </p>
                              <ul>
                                <li>LMV-1</li>
                                <li>LMV-2</li>
                                <li>LMV-4</li>
                                <li>LMV-9</li>
                              </ul>
                              <p>*Subjected to Technical Feasibility</p>
                              <p className="card_cont">
                                <span>Q. How to recharge prepaid meter?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Prepaid meter recharge coupon are available
                                at cash collection centre and can be paid via
                                cash/DD/Credit or debit card from our customer
                                care office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                or{" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday. Recharge can be done online via NPCL’s
                                website or PAYTM.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How does the alarm work in Pre-paid meter?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. If your credit gets below Rs.100/-, you
                                will have an alarm for 30 seconds. Press any
                                button to turn it off. The alarm will be
                                repeated every half an hour until recharged. It
                                is advised that once meter reached its minimum
                                limit recharge immediately to avoid
                                disconnection. You can also set minimum limit as
                                per your convenience.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Whom to contact in case of misplacing of
                                  Recharge Coupon?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Please visit the NPCL Cash Collection
                                Center for re-issuing of recharge coupon which
                                will be done free of cost. Consumer have to
                                mention BP no./ Meter no. to get the recharge
                                coupon. May please note- the coupon is specific
                                to your meter hence cannot be used elsewhere.
                              </p>
                              {/* <p className="card_cont">
                                <span>
                                  Q. How to convert post-paid connection into
                                  prepaid connection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. To get the post-paid connection to prepaid
                                connection, consumer can visit our Customer Care
                                Office at Knowledge Park -1 or Techzone-4,
                                Greater Noida . Consumer has to apply under
                                category conversion services by fill an
                                application form and submit their ID copy
                                Knowledge Park-I or Techzone-IV . Timings 10:00
                                am to 5:00 pm , Monday to Friday.
                              </p> 
                              <p className="card_cont">
                                <span>
                                  Q. What are the security deposit charges for
                                  conversion from post-paid to pre-paid or for
                                  new connection installation in Pre-paid?
                                </span>
                              </p>
                              */}
                              <p className="card_cont">
                                <span>
                                  Q. What are the documents required if meter
                                  get stolen?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. You have to submit an application, original
                                FIR, ID proof of registered consumer at our
                                Customer Care office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                or{" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                Note: Meter charges are applicable as per UPERC{" "}
                                <a href="../cost-data-book">cost data book</a>.{" "}
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What are the New Meter cost in Meter stolen
                                  cases?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Once the consumer process the application
                                for re-installation of meter in case of Meter
                                Stolen, applicable meter charges to paid as per
                                UPERC{" "}
                                <a href="../cost-data-book" target="_blank">
                                  cost data book
                                </a>{" "}
                                .
                              </p>
                              <p className="card_cont">
                                *Taxes Applicable on the new meter cost shall be
                                charged as per the norms.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Whom to contact if meter is not at the
                                  premises?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Please contact our Call at our 24X7 CALL
                                CENTER - 0120 6226666 or you may visit Customer
                                Care Office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>Q. Why my meter screen is blank.?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Please check if the supply is ON in the
                                area and you are facing No Power issue, if Yes,
                                you may contact 24X7 Call Centre No.
                                0120-6226666 to register complaint for No Supply
                                or WhatsApp Bot based services on 0120-6226666
                                or send SMS at 7840002288 ( #NOPOWER {"<Space>"}{" "}
                                Consumer No.) or visit at Customer Care office
                                at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                or{" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday. In case supply is ON but no display on
                                meter, register complaint for meter display
                                problem on above given numbers. Our Team will
                                visit your site for meter inspection within 7
                                working days.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. If my meter box has a husky display, what
                                  should I do ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Kindly contact 24X7 Call Centre No.
                                0120-6226666 or visit at our Customer Care
                                office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to Friday
                                and register complaint for meter display
                                problem. Our Team will visit your site for meter
                                inspection within 7 working days.You may also
                                register your complaint on our WhatsApp no.01206226666
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Where to report incase of No Display in the
                                  meter?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumer may contact at our 24X7 CALL
                                CENTER - 0120 6226666 or visit at our Customer
                                Care office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to Friday
                                and register complaint for meter display
                                problem. Our Team will visit site for meter
                                inspection within 7 working days.You may also
                                register your complaint on our WhatsApp no.01206226666
                              </p>
                              {/* <p className="card_cont">
                                <span>
                                  Q. What are the charges of meter testing?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Meter Testing Charges are as follows:
                              </p>
                              <ul>
                                <li>Single Phase Rs.207</li>
                                <li>Three Phase Rs.413</li>
                                <li>LT/CT, CT/HT Meter Rs.1180</li>
                              </ul>
                              <p className="card_cont">
                                *All Applicable taxes on the meter testing fee
                                shall be charged as per the norms.
                              </p> */}
                              <p className="card_cont">
                                <span>
                                  Q. What are the different options available
                                  for Meter Testing?
                                </span>
                              </p>
                              <ul>
                                Ans.
                                <li>
                                  Meter Testing request can be registered thru
                                  link mentioned below{" "}
                                  <a
                                    target="_blank"
                                    href="https://iwebapps.noidapower.com:8032/MTRCHPMT/MeterTesting_Reg.aspx"
                                  >
                                    https://iwebapps.noidapower.com:8032/MTRCHPMT/MeterTesting_Reg.aspx
                                  </a>{" "}
                                </li>
                                <li>
                                  You may also visit Customer Care Office at{" "}
                                  <a
                                    href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                    target="_blank"
                                  >
                                    Knowledge Park-I
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                    target="_blank"
                                  >
                                    Techzone-IV
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                    target="_blank"
                                  >
                                    Sigma-IV:- 33/11KV Substation
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                    target="_blank"
                                  >
                                    Ecotech-II: Electric Sub-Station
                                  </a>{" "}
                                  . Timings 10:00 am to 5:00 pm , Monday to
                                  Friday.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. How can I know the status of Meter Testing
                                  Request ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumer may visit our website
                                www.noidapower.com and refer Online E-Application
                                tab wherein "Online Meter Testing" option is
                                available.
                                <br />
                                You may check the status of meter testing by
                                calling to our 24X7 helpline numbers on or
                                0120-6226666.
                                <br /> You may also visit Customer Care Office
                                at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. On what basis the meter testing fee is
                                  imposed?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Meter testing fees is charged on "type of
                                meter" and it is applicable as per UPERC Tariff
                                Order.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Would you refund the testing fee if meter
                                  found ok?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. There is no provision for refund of testing
                                fees.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Will I receive testing report on meter
                                  testing? What is the procedure of complaining
                                  if I don’t receive one?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. After due inspection meter testing report
                                is handed over to consumer. In case you have not
                                received it, you may write us at
                                CRM@noidapower.com.
                                <br /> You may also visit Customer Care Office
                                at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Which parameter define that meter is faulty
                                  or meter testing is Ok?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Kindly note that meter testing is done on
                                many factors such as meter accuracy results ,
                                display parameters &amp; downloaded data
                                analysis on which testing engineer will confirm
                                whether meter is faulty or meter is working
                                normal.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Why meters are changed without any
                                  complaint?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. In case of technology upgradation or
                                periodic replacement from Old to New Generation
                                meter as per the Guideline of Hon’ble Regulatory
                                Commission.
                              </p>
                              <p className="card_cont">
                                A Distribution Licensee may change the meter
                                from time to time for any of the following
                                reasons:
                              </p>
                              <ul>
                                <li>Version Upgrade</li>
                                <li>Meter is burnt</li>
                                <li>Meter is faulty</li>
                                <li>Meter is damaged</li>
                                <li>No display in the meter</li>
                                <li>Suspected to be tampered</li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. How much time will you take to replace the
                                  faulty meter?
                                </span>
                              </p>
                              <p className="card_cont"></p>
                              <ul>
                                <li>
                                  <b>Meter Stop/Meter Damaged</b>: 15 working
                                  days for meter replacement
                                </li>
                                <li>
                                  <b>Meter Slow/Fast</b>: 15 working days
                                </li>
                                <li>
                                  <b>Meter Burnt</b>: 3 Working Days for
                                  replacement of the burnt meter
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. Whom to contact if there is No power after
                                  successful recharge?
                                </span>
                              </p>
                              <p className="card_cont">Ans.</p>
                              <ul>
                                <li>
                                  For Secure Prepaid meters
                                  <ul>
                                    <li>
                                      Contact your maintenance office it may be
                                      your main MCB be off which is located in
                                      Electric shaft in societies/ check your
                                      MCB status
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Radius Smart Meters(AEW Meters)
                                  <ul>
                                    <li>
                                      Contact your maintenance office or Check
                                      balance / supply status in mobile app.
                                    </li>
                                  </ul>
                                  <p>
                                    *You may also contact our 24X7 helpline
                                    numbers on 0120-6226666 or may visit NPCL
                                    Customer Care office at{" "}
                                    <a
                                      href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                      target="_blank"
                                    >
                                      Knowledge Park-I
                                    </a>{" "}
                                    or{" "}
                                    <a
                                      href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                      target="_blank"
                                    >
                                      Techzone-IV
                                    </a>{" "}
                                    :During 10:00 A.M to 5:00 P.M (Mon-Fri) to
                                    know more about the same.
                                  </p>
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. Will I receive a bill for Pre-paid meter
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Monthly e-bill will be sent to registered
                                email id &amp; phone number post bill
                                generation. It can also be downloaded from our
                                website via Quick E-bill Download : or follow
                                direct link: No payment is required to be made
                                against the bill. Ensure sufficient balance in
                                prepaid meter through timely recharge in order
                                to avail uninterrupted power supply.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Whom to contact if the meter doesn’t show
                                  the credit amount after recharge?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Transactions made online would go through a
                                process of validation before being updated in
                                the NPCL accounts. This may take upto 3 working
                                days. Therefore, please avoid making payments
                                using same payment gateway, you may try with
                                another payment options for smooth processing of
                                meter recharge.{" "}
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Why meter is beeping when it is already
                                  recharge?
                                </span>
                              </p>
                              <ul>
                                <p>Ans. </p>
                                <li>
                                  There is a provision in meter to set recharge
                                  limit if balance will go down from this limit
                                  then only meter will start beeping at low
                                  balance . This feature is configurable
                                  ,consumer itself can set this limit as per
                                  their consumption pattern.
                                </li>
                                <li>
                                  Freedom Bluetooth unit may be faulty ( if
                                  Showing Con. Fault in display ).
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. What options are available for pre-paid
                                  meter recharge?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. (A) Secure Prepaid Meters
                              </p>
                              <ul>
                                <li>
                                  Paytm, (there are many options to pay amount
                                  in paytm ex- Through UPl, Debit Card,credit
                                  Card and Net Banking)
                                </li>
                                <li>
                                  Consumer can get token from NPCL Customer Care
                                  Office, KP-1, Greater Noida
                                </li>
                              </ul>
                              <p className="card_cont">
                                (B) Radius Smart Meters( AEW Meters)
                              </p>
                              <ul>
                                <li>
                                  Paytm, Bill Desk (there are many options to
                                  pay amount in paytm ex- Through UPl, Debit
                                  Card,credit Card and Net Banking)
                                </li>
                                <li>NPCL Multipoint App., NPCL web site</li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. What is “CON FAULT”, what is to be done to
                                  get it corrected?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. CON FAULT” means connection faulty, because
                                of which meter will be displaying digit 17 which
                                means connection from meter to display unit is
                                interrupted. Kindly register your complaint on
                                our call center contact no. - 0120-6226666.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Can supply gets disconnected if meter is
                                  not recharged or do I get any reminder?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. (A) Secure Prepaid Meters
                              </p>
                              <ul>
                                <li>
                                  Reminder beep will come in minimun available
                                  balance. Supply will be disconnected in
                                  negative balance.
                                </li>
                              </ul>
                              <p className="card_cont">
                                (B) Radius Smart Meters(AEW Meters)
                              </p>
                              <ul>
                                <li>
                                  Go to NPCL Multipoint App by turning on low
                                  money notifications, consumer can get a
                                  reminder.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>Q. How to check the balance?</span>
                              </p>
                              <p className="card_cont">
                                Ans. (A) Secure Prepaid Meters
                              </p>
                              <ul>
                                <li>
                                  Meter Automatically showing Balance &amp;
                                  other details in Meter display.
                                </li>
                                <li>
                                  Consumers may check their account balance by
                                  pressing # button in key pad of meter display
                                  unit &amp; for single phase meters - Press key
                                  "1" single time to check balance.
                                </li>
                              </ul>
                              <p className="card_cont">
                                (B) Radius Smart Meters(AEW Meters)
                              </p>
                              <ul>
                                <li>
                                  Login in NPCL Multipoint App/ web and check
                                  balance.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. What should be the minimum balance to
                                  maintain pre-paid meter?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumer can change minimum balance in
                                meter acocording to consumption and requirement.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. If meter is showing different date from
                                  actual does it mean that it is faulty ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. It is suggested to get your meter tested
                                from NPCL to confirm whether the meter is faulty
                                or not.
                              </p>

                              <p className="card_cont">
                                <span>
                                  Q. Red light in meter indicates what?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Red LED in meter which indicates pulse /
                                revolution of meter , if Red light is blinking
                                in meter it means meter is running on load.
                              </p>

                              <p className="card_cont">
                                <span>
                                  Q. How can I see my consumption pattern in
                                  pre-paid meter ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. (A) Secure Prepaid Meters
                              </p>
                              <ul>
                                <li>
                                  By pressing Key"2" consumer can check
                                  consumptions of today, previous day, previous
                                  Week and current month)
                                </li>
                                <li>
                                  By Pressing key"7" consumer can check monthly
                                  consumptions of present calendar Year)
                                </li>
                              </ul>
                              <p className="card_cont">
                                (B) Radius Smart Meters ( AEW Meters)
                              </p>
                              <ul>
                                <li>
                                  Visit NPCL Multipoint App and consumer can see
                                  daily consumption( Current month) and Monthly
                                  consumptions( Present Calendar Year) by
                                  selecting "Report" option.
                                </li>
                                <li>
                                  Visit NPCL Multipoint Web, conumer can
                                  download and see consumptions by selecting
                                  "Report" option.
                                </li>
                              </ul>
                            </div>
                          ) : null}
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow8(!show8)}
                          >
                            <b>Payment Related</b>
                          </div>
                          {show8 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q.What are the different modes of payments
                                  available?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Modes of payments are as follows:
                              </p>
                              <p className="card_cont">
                                <b>A. Digital Payment through:-</b>
                              </p>
                              <ul>
                                <li>NPCL Website</li>
                                <li>NPCL’s Mobile App and BHIM App</li>
                                <li>PayTM, Mobiquick, and Other wallets</li>
                                <li>Bharat QR (printed on bills)</li>
                                <li>Bharat Bill Payment System (BBPS)</li>
                                <li>
                                  Utility bill payment option in Consumer’s
                                  internet banking accounts
                                </li>
                                <li>
                                  Auto- Debit facility through NACH (National
                                  Automated Clearing House)
                                </li>
                                <li>RTGS/ NEFT to NPCL’s accounts</li>
                                <li>
                                  Payment thru Credit & Debit cards at NPCL Cash
                                  Collection Center.
                                </li>
                                <li>
                                  Payment tab on WhatsApp Bot based services on
                                  0120-6226666
                                </li>
                              </ul>
                              <p className="card_cont">
                                <b>
                                  Non-Digital Payment Modes are as follows:-
                                </b>
                              </p>
                              <ul>
                                <li>
                                  Cash / Cheque / DD at Customer Care Office,
                                  KP-1 or Techzone-4* or to Village Contact
                                  Persons.
                                </li>
                                <li>Cheque/DD at NPCL’s Cheque Drop Boxes.</li>
                                <li>
                                  Cash at nearby BBPS authorised retail outlets.
                                </li>
                              </ul>
                              <p className="card_cont">
                                *You may visit Cash Collection Center at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                or{" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>Q.Where can I make cash payment?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Modes of payments are as follows:
                                A. Digital Payments through:-
                                <ul>
                                  <li>NPCL Website</li>
                                  <li>NPCL’s Mobile App and BHIM App</li>
                                  <li>PayTM, Mobiquick, and Other wallets</li>
                                  <li>Bharat QR (printed on bills)</li>
                                  <li>Bharat Bill Payment System (BBPS)</li>
                                  <li>
                                    Utility bill payment option in Consumer’s
                                    internet banking accounts
                                  </li>
                                  <li>
                                    Auto- Debit facility through NACH (National
                                    Automated Clearing House)
                                  </li>
                                  <li>RTGS/ NEFT to NPCL’s accounts</li>
                                  <li>Payment thru Credit &amp; Debit cards at
                                    NPCL Cash Collection Center.
                                  </li>
                                  <li>Payment tab on WhatsApp Bot based services
                                    on 0120-6226666
                                  </li>
                                </ul>
                                B. Non Digital Payment Modes are as follows:-
                                <ul>
                                  <li>Cash / Cheque / DD at Customer Care Offices
                                    or to Village Contact Persons.
                                  </li>
                                  <li>Cheque/DD at NPCL’s Cheque Drop Boxes.</li>
                                  <li>Cash at nearby BBPS authorised retail outlets.</li>
                                </ul>{" "}
                                *You may visit Cash Collection Center at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm, Monday to Friday.
                                For rural consumers, may please refer the
                                area wise payment center list for making cash payment.

                              </p>

                              <p className="card_cont">
                                <span>
                                  Q.What is the upper limit for Cash Payment.?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. As per section 6.10 of Electricity Supply
                                code 2005, you can make cash payment up to
                                Rs.20,000/- only. In case your bill is more than
                                the above mentioned value, remaining payment
                                shall be made via any mode other than cash.
                              </p>
                              {/* <p className="card_cont">
                                <span>
                                  Q.How to make online payment of Bills?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Online Payment of Bills can be made through
                                below mentioned modes :
                              </p>
                              <ul>
                                <li>NPCL Website</li>
                                <li>NPCL’s Mobile App and BHIM App</li>
                                <li>PayTM, Mobiquick, and Other wallets</li>
                                <li>Bharat QR (printed on bills)</li>
                                <li>Bharat Bill Payment System (BBPS)</li>
                                <li>
                                  Utility bill payment option in Consumer’s
                                  internet banking accounts
                                </li>
                                <li>
                                  Auto- Debit facility through NACH (National
                                  Automated Clearing House)
                                </li>
                                <li>RTGS/ NEFT to NPCL’s accounts</li>
                              </ul> 
                              <p className="card_cont">
                                For more details you may also visit NPCL
                                website.
                              </p>
                              */}
                              <p className="card_cont">
                                <span>
                                  Q.Do I need to pay extra charges if I wish to
                                  pay Online through NPCL website/Mobile App ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. NPCL do not levy any extra charges for
                                online payment. In case the amount being paid is
                                above 4000/-, Bill Desk will charge you a
                                convenience fee for rendering online payment
                                service at the following rate:
                              </p>
                              <p className="card_cont">
                                * For Credit card (Visa/Master Card)
                                transactions, 1 % of the amount being paid
                              </p>
                              <p className="card_cont">
                                * For Debit card (Visa/Master Card)
                                transactions, 0.80 % of the amount being paid
                              </p>
                              <p>
                                * For Net banking transactions, Rs. 3/-per
                                transaction
                              </p>
                              <p>
                                * For wallet transactions, Rs. 3/-per
                                transaction
                              </p>
                              <p>* For UPI transactions, NIL</p>

                              <p className="card_cont">
                                In case the amount being paid is above 4000/-,
                                Paytm will charge you a convenience fee for
                                rendering online payment service at the
                                following rate:
                              </p>

                              <p>
                                * For Paytm postpaid/Credit Card transactions, 1
                                % of the amount being paid
                              </p>
                              <p>
                                * For Debit card transactions, 0.78 % of the
                                amount being paid
                              </p>
                              <p>
                                * For Net banking transactions, Rs. 2.25/-per
                                transaction
                              </p>
                              <p>
                                * For Paytm wallet transactions, 0.95 % of the
                                amount being paid
                              </p>
                              <p>* For UPI transactions, NIL</p>

                              <p>
                                Applicable taxes on the Convenience fees shall
                                also be charged by Bill Desk & Paytm as per the
                                norms
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Is it safe to give my bank details on NPCL
                                  website ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Yes, It is completely safe. NPCL follows
                                highest level of security standards to ensure
                                that the data entered by consumers remains
                                confidential and not stored anywhere on NPCL
                                Server.
                              </p>
                              <p className="card_cont">
                                <span>Q.How can I make Cheque Payments</span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumer can drop cheque at following
                                Cheque Collection Centres of NPCL along with
                                latest bill stub (Lower portion of bill). The
                                cheques should be in favour of ""Noida Power
                                Company Limited"". Write down the cheque with
                                credentials like your Name, Consumer Number and
                                mobile number at the back of cheque.
                              </p>
                              <ul>
                                <li>NPCL Customer Care Centre (Sector KP-1)</li>
                                <li>NPCL Customer Care Centre (Techzone-IV)</li>
                                <li>
                                  NPCL Sub-station at Alpha-1, Opp Ryan
                                  International School
                                </li>
                                <li>
                                  NPCL Sub-station, Sector Omega-I, Builders
                                  Area, Near AWHO Apartments
                                </li>
                                <li>
                                  NPCL Sub-station at Surajpur, Near Surajpur
                                  Police Station
                                </li>
                                <li>
                                  Bar Association Office, Surajpur District
                                  Court
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.Can I make payment through Post dated
                                  cheques ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Post-dated and outstation cheques are not
                                accepted at NPCL.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What are the penalty charges applicable on
                                  cheque bounce?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. In case the Cheque gets bounced due to
                                insufficient funds or exceed arrangement, you
                                will not be able to make payment through cheque
                                in that Financial Year.
                                <br />
                                Penalty Charges applicable are mentioned below:
                              </p>
                              <ul>
                                <li>
                                  Cheque amount of less than Rs. 5000 : Rs.250
                                </li>
                                <li>
                                  Cheque amount from Rs. 5000 &amp; up to Rs.
                                  10000 : Rs. 500
                                </li>
                                <li>
                                  Cheque amount from Rs. 10000 &amp; up to Rs. 1
                                  Lac : Rs. 1000
                                </li>
                                <li>
                                  Cheque amount of Rs. 1 Lac and above : Rs.
                                  5000
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.Can I issue another cheque, if my cheque
                                  bounces?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. In case the Cheque got bounced due to
                                insufficient funds or exceed arrangements, the
                                consumer will not be able to make payment
                                through cheque in that Financial Year.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Can I issue cheque, if my supply is
                                  Disconnected ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Yes, consumer can pay their bill amount
                                through cheque (Considering no cheque bounce
                                history).
                              </p>
                              <p className="card_cont">
                                Payment can be done for outstanding bill and
                                reconnection charges as below:-
                              </p>
                              <ul>
                                <li>
                                  Bill payment via cheque and reconnection
                                  charge via cash and vice versa.
                                </li>
                                <li>
                                  Bill payment and reconnection charge together
                                  via cash.
                                </li>
                                <li>
                                  Bill payment and reconnection charge together
                                  via cheque . Please note, if in case the same
                                  get dishonoured, the supply will be
                                  disconnected and the Reconnection amount has
                                  to be paid again.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.When will my online payment be updated in my
                                  account?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. All payments made through other than RTGS /
                                NEFT mode are updated on Real-time basis in
                                consumers account except during system
                                shutdown/maintenance when it may take up to 2 to
                                3 working days.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Where can I approach to register my payment
                                  related complaint?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Complaints can be registered at:
                              </p>
                              <ul>
                                <li>NPCL Website.</li>
                                <li>NPCL Mobile App.</li>
                                <li>
                                  Our 24X7 Call centre at 0120-6226666 /
                                  2333555.
                                </li>
                                <li>
                                  You may also visit Customer Care Office at{" "}
                                  <a
                                    href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                    target="_blank"
                                  >
                                    Knowledge Park-I
                                  </a>{" "}
                                  or{" "}
                                  <a
                                    href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                    target="_blank"
                                  >
                                    Techzone-IV
                                  </a>{" "}
                                  . Timings 10:00 am to 5:00 pm , Monday to
                                  Friday.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.What are the GST % will be charged
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. GST will be levied as per GST regulations
                                and rates applicable to various services
                                provided by NPCL.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.For which services I have to pay GST
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. As per GST Circular No. 34/08/2018-GST
                                dated 01st March 2018, GST is applicable on all
                                the services provided by the NPCL except energy
                                charges.
                              </p>
                            </div>
                          ) : null}
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow7(!show7)}
                          >
                            <b>Billing Related</b>
                          </div>
                          {show7 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q.How are bills prepared if the meter is not
                                  read?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. If the actual meter reading is not acquired
                                due to any reason; provisional bill will be
                                raised which is subjected to adjustment upon
                                receipt of actual meter reading in the
                                subsequent months. Provisional bills are raised
                                on average consumption of last 3 months.
                                Inaccessibility to premises for 3 months or more
                                could lead to disconnection of supply as per
                                Electricity ̀ Supply Code 2005.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What is the normal reading span for monthly
                                  Meter Reading?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Meter reading is normally taken between a
                                period of 28 to 32 days from the last meter
                                reading date. The same is intimated to you
                                through SMS if your mobile number is registered
                                with us. Fixed Charges and slabs for Energy
                                charges are calculated proportionally for the
                                billing period.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.How to calculate / understand electricity
                                  bill?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans.Please refer back side of electricity bill
                                to check applicable Tariff and Bill Calculation.
                                Additionally, you can reach out to us at
                                crm@noidapower.com
                              </p>
                              <p className="card_cont">
                                <span>Q.What is the meter reading system?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Reading of three phase meters is taken
                                remotely through AMR or LPR facility. For Single
                                Phase meters, meter readers go door-to-door and
                                records reading in Hand Held Devices (HHD) /
                                Meter Reading Terminals (MRT). Reading
                                information can also be conveyed through
                                SMS/Email registered with us.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my meter is not read regularly?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Any one of the following can be the
                                reasons:
                              </p>
                              <ul>
                                <li>
                                  Your premises are vacant / locked for long
                                  period of time or constantly during daytime
                                  resulting in inaccessibility of meter to the
                                  reader.
                                </li>
                                <li>
                                  Discrepancy in address in our database with
                                  the actual address may result in meter reader
                                  missing out your premises.
                                </li>
                              </ul>
                              <p className="card_cont">
                                If you observe such shortcomings in the address
                                (as printed in bill), please bring the same to
                                the notice of Customer Relation Officer at
                                Customer Care Office,NPCL immediately for
                                corrections.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Where to approach when meter is not read ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. If consumer premises is found locked, meter
                                reader will stick a ""miss you"" sticker
                                intimating about the failed reading attempt
                                alongwith a contact number to coordinate the day
                                &amp; time of reading. Or you may:
                              </p>
                              <ul>
                                <li>
                                  Call at our 24X7 CALL CENTER - 0120 6226666
                                </li>
                                <li>Mail your concern at crm@noidapower.com</li>
                                <li>
                                  WhatsApp Bot based services on 0120-6226666
                                </li>
                                <li>
                                  Send SMS at 7840002288 #WRONGREADING{" "}
                                  <space>
                                    {" "}
                                    Consumer Number (Only which is showing in
                                    KWH)
                                  </space>
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.Why fixed charges are not constant?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Fixed Charges defined by Monthly Tariff
                                applies to a period of 30 days. When billing
                                period is not 30 days, applicable fixed charges
                                are calculated on prorata basis.
                              </p>
                              <p className="card_cont">
                                <span>Q.What are Penal Demand Charges</span>
                              </p>
                              <p className="card_cont">
                                Ans. Use of load beyond the sanctioned load is a
                                cognisable offence. Penal demand charges are
                                levied in excess to fixed charges based on
                                demand usage over and above the sanctioned load.
                                It is thus advised to enhance your contracted
                                load to avoid penal demand charges.
                              </p>
                              <p className="card_cont">
                                <span>Q.How to calculate slab ?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Slab based energy rates defined by Tariff
                                is applicable for 30 days per month and is
                                prorated on simple unitary method based on
                                number of billing days i.e. difference between
                                current reading date and previous reading date
                                (both dates inclusive)
                              </p>
                              <p className="card_cont">
                                <span>Q.What are Makeup Charges?</span>
                              </p>
                              <p className="card_cont">
                                Ans. As per approved Tariff, consumers who are
                                part of LMV-2 (Commercial Supply) and LMV-9
                                (Temporary Supply) are subjected to load based
                                minimum charges. If the sum of Fixed Charges
                                (for LMV-2 only) and Energy Charges is less than
                                applicable monthly minimum charge then
                                differential amount is levied as Make Up
                                Charges. Makeup charge = minimum charge - (fixed
                                charges + energy charge).
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Wrong reading mentioned on my bill. How to
                                  get it rectified?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans.Request for wrong reading can be registered
                                through following touchpoints
                              </p>
                              <ul>
                                <li>
                                  Call at our 24X7 CALL CENTER - 0120 6226666
                                </li>
                                <li>
                                  Email your concern at crm@noidapower.com. Do
                                  attach meter reading photograph for
                                  convenience.
                                </li>
                                <li>
                                  You may reach out us through our WhatsApp Bot
                                  based services on 0120-6226666
                                </li>
                                <li>
                                  Send SMS at 7840002288 #WRONGREADING{" "}
                                  {"<Space>"} Consumer Number {"<Space>"}{" "}
                                  Reading (Only which is showing in KWH)
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.What to do if I am not satisfied with the
                                  meter reading?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. If you are not satisfied with your meter
                                reading, you may
                              </p>
                              <ul>
                                <li>
                                  Call at our 24X7 CALL CENTER - 0120 6226666
                                </li>
                                <li>Mail your concern at crm@noidapower.com</li>
                                <li>
                                  You may reach out us through our WhatsApp Bot
                                  based services on 0120-6226666
                                </li>
                                <li>
                                  Send SMS at 7840002288 #SELFREADING{" "}
                                  {"<Space>"} Consumer Number {"<Space>"}{" "}
                                  Reading (Only which is showing in KWH)
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.What is the Frequency of billing ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Billing for every consumer is done on
                                Monthly basis
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.How electricity bills are delivered?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Bills are delivered through third party
                                vendor every month. You are requested to
                                register your email ID &amp; subscribe for e-bill
                                option for on-time and assured delivery of bill.
                              </p>
                              <p className="card_cont">
                                In case you need duplicate bill copy, may please
                                click on below link given on our website :
                                <br />{" "}
                                <a
                                  href="https://iwebapps.noidapower.com:8032/Ebill_download.aspx"
                                  target="_blank"
                                >
                                  https://iwebapps.noidapower.com:8032/Ebill_download.aspx
                                </a>{" "}
                                You may reach out us through our WhatsApp Bot
                                based services on 0120-6226666
                              </p>
                              <p className="card_cont">
                                <span>Q.How to get duplicate bill ?</span>
                              </p>
                              <p className="card_cont">
                                Ans. In case you need duplicate bill copy, may
                                please click on below link given on our website{" "}
                                <a
                                  href="https://iwebapps.noidapower.com:8032/Ebill_download.aspx"
                                  target="_blank"
                                >
                                  https://iwebapps.noidapower.com:8032/Ebill_download.aspx
                                </a>{" "}
                                or You may also login to our website or Mobile
                                www.noidapower.com App using your credentials
                                (Username &amp; Password) for downloading /
                                viewing last 6 bills.
                              </p>
                              <p className="card_cont">
                                In case you have not registered on our website,
                                kindly go through the “Need help in Sign In” tab
                                under My Account tab.
                              </p>
                              <p className="card_count">
                                You may also reach out us through our WhatsApp Bot
                                based services on 0120-6226666
                              </p>
                              <p className="card_cont">
                                <span>Q.What is DPS and Rebate?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Payment before Due Date allows consumers to
                                avail rebate of 1% on Fixed and Energy Charges.
                                DPS means Delayed Payment Surcharge which is
                                applicable due to non-payment of bills upto due
                                date.
                              </p>
                              <p className="card_cont">
                                <span>Q.How DPS is charged in the bill?</span>
                              </p>
                              <p className="card_cont">
                                Ans. If a consumer fails to pay his electricity
                                bill by due date specified therein, a late
                                payment surcharge shall be levied at 1.25% on
                                the dues per month up-to first three months of
                                delay. Post three months, DPS will be charged at
                                2.00% per month on the unpaid dues. In case of
                                constant non-payment, your elctricity connection
                                can be disconnected.
                              </p>
                              <p className="card_cont">
                                <span>Q. DPS waiver.?</span>
                              </p>
                              <p className="card_cont">
                                Ans. DPS is levied as per Rate Schedule approved
                                by Hon'ble UPERC and no-one can waive the same
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.How to lodge complaint through SMS?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. You can raise your complaint &amp; avail
                                other important information by sending SMS on
                                7840002288 from your registered mobile number.
                                To see list of short codes with mandatory
                                details, click on
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Is there any rebate if I pay bills online?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. No, there is no additional rebate provided
                                for online payments. However, a rebate of 1%
                                against sum of Demand Charges &amp; Energy
                                Charges is provided upon payment within due
                                date.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why there are different colour bills? It
                                  denotes what ?<span></span>
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Multi colour bills are designed to reflect
                                upon varied consumer behaviour towards payment
                                of dues and usage of electricity.
                              </p>
                              <ul>
                                <li>Blue Bill denotes timely bill payments</li>
                                <li>
                                  Green Bill denotes payment through digital
                                  mode and/or Solar Net Metering Consumer
                                </li>
                                <li>
                                  Orange Bill denotes delayed payment and/or
                                  arrears in current bill
                                </li>
                                <li>
                                  Red bill cautions consumer to abstain from
                                  unauthorized use of electricity based on site
                                  reporting.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.How much interest paid on Security Deposit?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Interest is credited to consumers against
                                existing security deposit on annual basis as per
                                the bank rate prescribed by the RBI.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What would be the disconnection date once
                                  arrear is paid from the current month bill?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. It is advised to clear entire dues in event
                                of arrears amount in current bill. However, if
                                payment is made only against arrears, then
                                disconnection date against current dues is 15
                                days from the due date as mentioned on the
                                electricity bill.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Who can submit self meter reading ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. The facility of self submission of meter
                                reading is available for single phase consumers.
                                Reading can be submitted through SMS,{" "}
                                <a
                                  href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx"
                                  target="_blank"
                                >
                                  Website
                                </a>{" "}
                                or App in the dedicated slot provided every
                                month.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Will I receive a bill for Pre-paid meter? Do
                                  I need to make payment against bill?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Monthly e-bill will be sent to registered
                                email id &amp; phone number post bill
                                generation. It can also be downloaded from our{" "}
                                <a
                                  href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx"
                                  target="_blank"
                                >
                                  Website
                                </a>{" "}
                                via Quick E-bill Download or follow direct link:
                              </p>
                              <p className="card_count">
                                You may also reach out us through our WhatsApp Bot
                                based services on 0120-6226666
                              </p>
                              <p className="card_cont">
                                No payment is required to be made against the
                                bill. Ensure sufficient balance in prepaid meter
                                through timely recharge in order to avail
                                uninterrupted power supply.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.How &amp; when self reading can be provided
                                  by consumer?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Reading submission slot is 1st to 10th day
                                of every month. You can easily reach out to us
                                by sending SMS at 7840002288 (#SELFREADING
                                {"<space>"}
                                Consumer No.
                                {"<space>"}
                                Reading) or our WhatsApp Bot based services on
                                0120-6226666. The service is also available on
                                our{" "}
                                <a
                                  href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx"
                                  target="_blank"
                                >
                                  Website
                                </a>{" "}
                                &amp; app.
                              </p>
                              <p className="card_cont">
                                * Please note that reading should be in KWH
                                parameter
                              </p>
                              <p className="card_cont">
                                <span>Q.What is power factor?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Power factor for the month is determined as
                                the ratio of KWH (Active Power Consumption) to
                                KVAH(Apparent power consumption).
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What is the minimum Power Factor to be
                                  maintained?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. As per the regulations of U.P. Electricity
                                Supply Code, 2005 minimum power factor to be
                                maintained is 0.75. If recorded power factor is
                                less than 0.75,suitable action as per the
                                provisions shall be initiated by NPCL.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What can be the reason which may cause dip
                                  in Power Factor? How it is to be maintained?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Majority of three phase connections
                                constitutes of inductive load which draws non
                                working / reactive power thereby increasing the
                                KVAH(Apparent power consumption). Use of
                                capacitor banks is helpful in offsetting this
                                non working power requirement, but it should be
                                regulated according to variance in load
                                requirement by installing Automated Power Factor
                                Controller(APFC). Regular check up and
                                maintenance is also advised.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why it is important to maintain Power
                                  Factor?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumers above 10 KW are billed on KVAH
                                basis as per the applicable tariff provisions.
                                Dip in power factor(P.F.) cause increase in KVAH
                                consumption which in turn results in higher
                                energy charges for the consumer. Also, it
                                reduces the effective distribution network
                                capacity of NPCL which can lead to power outage
                                in the area.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What are the consequences if power factor
                                  controller is not working properly?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Failure of power factor controller i.e.
                                APFC/Capacitor banks will lead to
                                inadequate/over compensation of reactive power
                                requirement. In both scenarios, KVAH consumption
                                will suddenly increase leading to higher energy
                                charges being billed.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Can NPCL help in improving Power Factor?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. No, it is consumer's responsibility to
                                maintain their power factor from time to time
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What is the Frequency of billing ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Billing for every consumer is done on
                                Monthly basis
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How electricity bills are delivered?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Bills are delivered through third party
                                vendor every month. You are requested to
                                subscribe for e-bill for on-time and assured
                                delivery of bill.
                              </p>
                              <p className="card_cont">
                                <span>Q. How to get duplicate bill ?</span>
                              </p>
                              <p className="card_cont">
                                Ans. "You are required to login on our website
                                or Mobile www.noidapower.com App using your
                                credentials (Username &amp; Password) for
                                downloading / viewing last 6 bills.
                              </p>
                              <p className="card_cont">
                                In case you have not registered on our website,
                                kindly go through the “Need help in Sign In” tab
                                under My Account tab".
                              </p>
                              <p className="card_cont">
                                <span>Q. What is DPS and Rebate?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Payment before Due Date allows consumers to
                                avail rebate of 1% on Fixed and Energy Charges.
                                DPS means Delayed Payment Surcharge which is
                                applicable due to non-payment of bills upto due
                                date.
                              </p>
                              <p className="card_cont">
                                <span>Q. How DPS is charged in the bill?</span>
                              </p>
                              <p className="card_cont">
                                Ans. If a consumer fails to pay his electricity
                                bill by due date specified therein, a late
                                payment surcharge shall be levied at 1.25% on
                                the dues per month up-to first three months of
                                delay. Post three months, DPS will be charged at
                                2.00% per month on the unpaid dues. In case of
                                constant non-payment, your elctricity connection
                                can be disconnected.
                              </p>
                              <p className="card_cont">Q. DPS waiver.?</p>
                              <p className="card_cont">
                                Ans. DPS is levied as per Rate Schedule approved
                                by Hon'ble UPERC and no-one can waive the same.
                              </p>
                              <p className="card_cont"></p>
                              <p className="card_cont">
                                {" "}
                                <span>
                                  Q. How the differential units are getting
                                  calculated on common (Physical/Virtual)
                                  connection  for multipoint connections?
                                </span>{" "}
                              </p>
                              <p className="card_cont">
                                Ans.{" "}
                                <b>
                                  Differential units calculation in physical
                                  common meter :
                                </b>{" "}
                                As per the 13th amendment of supply code dated:
                                10-08-2018, the total energy consumption of the
                                electricity recorded at the reference meter
                                shall be compared with the total electricity
                                consumption of all the individual meters &
                                common area meter. In case the difference in
                                energy consumption for any billing cycle works
                                out to be higher than permissable loss level
                                i.e. 4% in case of 11KV supply / 5% in case of
                                voltage higher than 11 KV, then the difference
                                of energy beyond permissible loss level shall be
                                added in the consumption of common area billing.
                                <br />
                                <b>
                                  Differential units calculation in Virtual
                                  common meter :
                                </b>
                                As per the 13th amendment of supply code dated:
                                05-08-2020, no physical common meter is to be
                                installed at consumer premise and differential
                                units calculation is to be done on the basis of
                                the logic as described below: Total energy
                                consumption of the electricity recorded at the
                                reference meter shall be compared with the total
                                electricity consumption of all the individual
                                meters. In case the difference in energy
                                consumption for any billing cycle works out to
                                be higher than permissable loss level i.e. 4% in
                                case of 11KV supply / 5% in case of voltage
                                higher than 11 KV, then the difference of energy
                                beyond permissible loss level shall be divided
                                among all the individual consumers in proportion
                                to their contractual load.
                              </p>

                              <p className="card_cont">
                                {" "}
                                <span>
                                  Q. What is TDS/TCS charged in bills?
                                </span>{" "}
                              </p>
                              <p className="card_cont">
                                Ans. <b>TDS (Tax Deduction at Source):</b> The
                                tax shall be deducted under Section 194Q by a
                                consumer/buyer carrying on a business whose
                                total sales, gross receipts or turnover from the
                                business exceeds Rs. 10 crores during the
                                financial year immediately preceding the
                                financial year in which such goods are
                                purchased.
                                <br />
                                The tax shall be deducted by the consumer/buyer
                                of goods at the rate of 0.1% of the purchase
                                value exceeding Rs. 50 lakhs.
                                <br />
                                <b>TCS (Tax Collection at Source):</b> The
                                Finance Act, 2020 inserted sub-section (1H) in
                                section 206C with effect from 1st Oct’20 to levy
                                the tax collection at source on sale of goods.
                                The Company is liable to collect TCS at the rate
                                of 0.1% on consideration received from a
                                Consumer in excess of fifty lakh rupees in a
                                financial year.
                                <br />
                                In non-PAN/ Aadhaar* cases the rate shall be 1%
                                .<br />
                                In case of PAN is not available or invalid PAN,
                                the tax shall be deducted at the rate of 5%.
                              </p>

                              <p className="card_cont">
                                {" "}
                                <span>
                                  Q. What is Additional Security Deposit (ASD)
                                  charged in bills, when it is charged and how
                                  it is calculated?
                                </span>{" "}
                              </p>
                              <p className="card_cont">
                                Ans. As per Clause 4.2 (e) of UPERC Supply Code,
                                2005: If the security deposit falls short of
                                covering the estimated power consumption bill
                                for 45 days based on his average monthly
                                consumption for the preceding financial year,
                                then demand for Additional Security Deposit
                                (ASD) is made to consumers equivalent to the
                                difference of amount between the existing
                                security deposit and 45 days average power
                                consumption bill. Also, the demand for ASD is
                                only made when the required ASD payable by the
                                consumer exceeds 10% of the existing security
                                deposit.
                              </p>
                            </div>
                          ) : null}
                        </div>

                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow6(!show6)}
                          >
                            <b>Theft Related</b>
                          </div>
                          {show6 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q. Where I can contact in case of Direct theft
                                  booked on my consumer number.?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. You may visit to NPCL Office Located at{" "}
                                <a href="https://goo.gl/maps/nAa5rBqkZPZeG3pP9 ">
                                  KP-II
                                </a>{" "}
                                from 10:00 A.M to 3:00 P.M (Mon-Fri) for any
                                detailed information about theft booked or you
                                may also contact our 24X7 CALL CENTER - 0120
                                6226666 or use WhatsApp Bot based services on
                                0120-6226666.
                              </p>
                              <p className="card_cont">
                                <span>Q. How to report Direct Theft</span>
                              </p>
                              <p className="card_cont">
                                Ans. You can call and complain against theft of
                                electricity / tampering of meter through
                                following avenues:
                              </p>
                              <ul>
                                <li>Helpline Number : 9891222240</li>
                                <li>
                                  <a href="https://www.noidapower.com/">Noidapower.com</a>
                                </li>
                                <li>Mobile App</li>
                                <li>CALL CENTER - 0120 6226666</li>
                              </ul>
                              <p className="card_cont">
                                Your identity shall remain confidential and
                                action will be taken at the earliest.
                              </p>
                              <p className="card_cont">
                                <span>Q. What is meant by “Direct Theft”?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Section 135(1) of Electricity Act, 2003 as
                                amended by Electricity (amendment) Act , 2007,
                                Theft of electricity is defined as, whoever
                                dishonestly,
                              </p>
                              <ul>
                                <li>
                                  a) Taps Electricity line:
                                  <ul>
                                    <li>- overhead</li>
                                    <li>- underground</li>
                                  </ul>
                                </li>
                                <li>
                                  b) Tampers meter:
                                  <ul>
                                    <li>- usage of tampered meter</li>
                                    <li>- current reversing transformer</li>
                                    <li>- loop connection</li>
                                    <li>
                                      - other device interferes with
                                      accurate/proper registration.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  c) Other device/method result in a manner
                                  whereby:
                                  <ul>
                                    <li>- electricity is stolen</li>
                                    <li>
                                      - damages/destroys an electric meter/
                                      apparatus/equipment/wire/ cause
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  d) Uses electricity through a tampered meter
                                </li>
                                <li>
                                  e) Uses electricity for purposes other than
                                  that which was authorized.
                                </li>
                              </ul>
                            </div>
                          ) : null}
                        </div>

                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow5(!show5)}
                          >
                            <b> Disconnection Related</b>
                          </div>
                          {show5 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q. How to apply for permanent disconnection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. If you want your connection to be
                                permanently disconnected, you have to submit the
                                ID proof, last paid bill with our written
                                application form at Customer Care Oﬃce at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                .
                                <br />* 2 Years Lock-in Period is applicable for
                                all consumers except LMV-9 (Temporary
                                Connection) from last date of any change in
                                contract. Please follow the link to know{" "}
                                <a href="https://www.noidapower.com/Document-Checklist">
                                  documentation requirement
                                </a>{" "}
                                .
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What are the Timelines of Permanent
                                  Disconnection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Disconnection of supply will completed
                                within 30 working days.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Can I apply for the same meter after
                                  surrendering of current meter?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Once the connection is permanently
                                disconnected, consumer has to follow{" "}
                                <a href="https://www.noidapower.com/Document-Checklist">
                                  new connection application{" "}
                                </a>
                                process for getting an electricity connection.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How to apply for refunding of security
                                  funds?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. You may visit Consumer Care Centre at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                , Timings 10:00 am to 5:00 pm, Monday to Friday.
                                and submit all the requisite documents which
                                are mentioned at the back side of application
                                form. Please follow the link to know{" "}
                                <a href="https://www.noidapower.com/Document-Checklist">
                                  documentation requirement
                                </a>{" "}
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Why and when MCG charges are applicable for
                                  Permanent Disconnection application?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. MCG calculated as per provisions of
                                Electricity Supply Code, 2005. The Tariff Orders
                                issued by Hon’ble Uttar Pradesh Electricity
                                Regulatory Commission (“State Commission”) from
                                time to time.Clause 4.14 (Agreement) - Point (h)
                                However, if the agreement is to be terminated
                                before completion of 2 years: 30 (i) Consumer
                                shall be liable to pay the minimum charges (or
                                the demand / fixed charges, if no minimum
                                charges are prescribed for that category) for a
                                period of 6 months or the period by which the
                                total duration of the agreement falls short of 2
                                years, whichever is lower.
                              </p>
                            </div>
                          ) : null}
                        </div>

                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow4(!show4)}
                          >
                            <b>Reconnection Related</b>
                          </div>
                          {show4 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q. How to apply for reconnection after making
                                  the part payment of outstanding amount?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Reconnection is not allowed on part
                                payment.
                              </p>

                              <p className="card_cont">
                                <span>
                                  Q. What are the charges for Reconnection
                                  within 6 Months?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Reconnection charges will be based on below
                                mentioned criteria:
                              </p>
                              <table className="table table-responsive">
                                <thead>
                                  <tr>
                                    <td>Criteria (load &amp; Category) </td>
                                    <td>DR charges (Rs.)* </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Pre-paid Meters - Single phase </td>
                                    <td>- Zero</td>
                                  </tr>
                                  <tr>
                                    <td>Pre-paid Meters -3 phase </td>
                                    <td>- Zero</td>
                                  </tr>
                                  <tr>
                                    <td>Smart Meters - Single phase </td>
                                    <td>- 100</td>
                                  </tr>
                                  <tr>
                                    <td>Smart Meters - 3 phase </td>
                                    <td>- 200</td>
                                  </tr>
                                  <tr>
                                    <td>&gt;75 KW/100BHP </td>
                                    <td>- 2000</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      LMV-6 (having load &lt;=75KW/100BHP){" "}
                                    </td>
                                    <td>- 1000</td>
                                  </tr>
                                  <tr>
                                    <td>All others categories </td>
                                    <td>- 600</td>
                                  </tr>
                                </tbody>
                              </table>
                              <p></p>
                              <p className="card_cont">
                                Note: * 18% GST is applicable on DR charges and
                                supply will be reconnected within 48 hrs. for
                                Rural and 24 hrs. for urban after payment of
                                Outstanding dues along with reconnection
                                charges.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What are the Timelines for Reconnection
                                  after temporary disconnection of supply?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Timeline for reconnection of supply will be
                                Maximum of 48 Hrs for Rural and 24 hrs in Urban
                                after payment of bill &amp; Reconnection
                                charges.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How to apply for reconnection when meter
                                  already removed at site?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. There is no such provision to reconnect the
                                meter however consumer has to apply for new
                                connection with all required documents.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How to apply for reconnection after final
                                  bill generation?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. There is no such provision of reconnection.
                                Once the payment of final bill is done, consumer
                                has to apply for new connection with all
                                required documents.
                              </p>

                              <p className="card_cont">
                                <span>
                                  Q. What steps to be taken in case of Prov.
                                  TD of connection ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. In case the connection is Prov. TD, you may
                                have to submit a written application along with
                                ID proof & current meter reading photo with a
                                concent that in future reading shall be made
                                available during periodic billing cycle at Customer
                                Care Office at {" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "},
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                            </div>
                          ) : null}
                        </div>

                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow3(!show3)}
                          >
                            <b>Website / Mobile App Related</b>
                          </div>
                          {show3 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q. What is the procedure to Login on{" "}
                                  <Link to="/" target="_blank">
                                    www.noidapower.com ?
                                  </Link>
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Go to www.noidapower.com. Click on
                                “Consumer Login” and register your Consumer
                                Number or click on the link: www.npcl.com
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What are the various services/information
                                  available on NPCL's website?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans.The main features of website are as follows:
                              </p>
                              <ul>
                                <li>
                                  About NPCL and Contact Details of registered
                                  offices
                                </li>
                                <li>Bill Payment Option</li>
                                <li>Outage Alerts</li>
                                <li>Service Application and Complaint</li>
                                <li>Tariff Details</li>
                                <li>Report a Concern</li>
                                <li>Account Registration</li>
                                <li>CSR activities</li>
                                <li>Self Meter Reading</li>
                                <li>Quick Ebill Download</li>
                                <li>Quick Bill Payment</li>
                                <li>Contact Us</li>
                                <li>Online Application</li>
                                <li>Payment Options</li>
                                <li>Pre-paid Meter Recharge</li>
                                <li>
                                  Consumer Services for Application form,
                                  Document Checklist, Online Meter Testing, SMS
                                  Based Services, WhatsApp based services,
                                  Customer Care office Queue Status, Single to
                                  Multipoint Conversion, Consumer Awareness and
                                  Many more.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>Q. How to reset password ?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Password can be reset after clicking forgot
                                password option. You have to punch your
                                registered email id and consumer number to
                                receive an updated password , if problem still
                                persist please write us at crm@noida power.com)
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How and from where a consumer can update
                                  his contact details ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. For updating contact details (mobile
                                number/ email-id) consumer can login to our
                                website or mobile app. www.noidapower.com You
                                may also visit our customer care oﬃce, Knowledge
                                Park-I or Techzone-IV . Timings 10:00 am to 5:00
                                pm , Monday to Friday, along with the BP No. and
                                ID proof of registered consumer or company. You
                                may also write us at crm@noidapower.com
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Is it safe to give my bank details on NPCL
                                  website?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Yes, It is completely safe. NPCL follows
                                highest level of security standards to ensure
                                that the data entered by consumers remains
                                conﬁdential.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Do I need to pay extra charges for online
                                  payment?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. NPCL does not levy any extra charge for
                                online payment. However, the Payment Gateway,
                                Wallets and other service providers may levy
                                service charges along with applicable taxes for
                                transaction above Rs. 4,000/-.
                              </p>
                              <p className="card_cont">
                                In case the transaction is made through Paytm
                                Gateway service charges would be levied at
                                following rate:
                              </p>
                              <ul>
                                <li>
                                  For credit/debit card transaction, 1.10% of
                                  the transaction amount being paid
                                </li>
                                <li>
                                  For Net banking transaction, Rs.4/-per
                                  transaction
                                </li>
                              </ul>
                              <p className="card_cont">
                                In case the transaction is made through Bill
                                desk Gateway service charges would be levied at
                                following rate:
                              </p>
                              <ul>
                                <li>
                                  For credit/debit card transaction, 1% of the
                                  transaction amount being paid
                                </li>
                                <li>
                                  For Net banking transaction, 3% per
                                  transaction
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. Why this month bill is not updated on
                                  Website Mobile app ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Bills are generated from time to time from
                                commercial department. It might be possible due
                                to some certain reason bill of this month may be
                                delayed and it’s not generated yet. Once it will
                                have generated in SAP system then PDF and bill
                                details will appear on Website.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What happen if my ID is locked in NPCL
                                  Website?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. USERID will be automatic unlock in 24 hours
                                for the website users
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What all the features available on Mobile
                                  App ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. After Login features are as follows :
                              </p>
                              <ul>
                                <li>Bill and Payment History</li>
                                <li>Register Request &amp; Complaints</li>
                                <li>Consumer Consumption Analysis</li>
                                <li>Energy Calculator</li>
                                <li>Request &amp; Complaint Status</li>
                                <li>Subscribe for E-bill</li>
                                <li>Self-Meter Reading</li>
                                <li>Quick Payment</li>
                                <li>Energy Tips</li>
                                <li>KYC Updation</li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. What is Un-Authenticated Sender issue.
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. User details like USERNAME, Email ID and
                                Password are not matched when user face this
                                kind of problem.
                              </p>
                            </div>
                          ) : null}
                        </div>

                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow2(!show2)}
                          >
                            <b>Complaint Against Services Related</b>
                          </div>
                          {show2 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q. What is the process of applying for New
                                  Connection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Applicant can download or ﬁll the online
                                New Connection Service form from our website and
                                upload necessary documents www.noidapower.com as
                                mentioned in the documents checklist. You can
                                also visit our Customer Care Oﬃce at Knowledge
                                Park-I or Techzone-IV . Timings 10:00 am to 5:00
                                pm , Monday to Friday, to apply in person.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Within how much time should the connection
                                  be provided to me?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Once the application and necessary
                                documents are reviewed at NPCL, and estimate
                                gets generated which needs to be paid by the
                                consumer. Post which it will take approximately
                                7 working days* for the apparatus to be
                                installed at the consumers premises and for the
                                energization of supply.
                              </p>
                              <p className="card_cont">
                                *This SLA is applicable for the urban domestic
                                connection where no augmentation of main is
                                required.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why I have not received my estimate
                                  intimation?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Once you have successfully submitted your
                                new service application form at our Customer
                                Care Office or Online , we process the
                                application in our system and it gets forwarded
                                to concerned department for the Commercial &amp;
                                LCC Dues, Technical feasibility check. Once
                                clearance is received from all the departments
                                it is followed by estimate preparation. During
                                this process system generated messages are
                                triggered, after completion of necessary stages.
                                Thus, you are requested to please wait for the
                                same as we will be working on 1st cum 1st serve
                                basis.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Can I pay the Estimate amount Online
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Yes , you can make payment online thru NPCL
                                website or you may visit our Customer Care
                                Office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday
                                to apply in person for payment of estimate.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Estimate paid but meter not installed yet?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Post payment of estimated amount, New
                                connection will be provided within 7 days where
                                no Augmentation of existing mains are required.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Whom to contact for the status of
                                  application?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Kindly visit our website www.noidapower.com &amp;
                                refer consumer portal tab wherein option is available
                                under digital services for application status check.
                                You may click the direct link : {" "}
                                <a
                                  href="https://iwebapps.noidapower.com:8032/NotificationStatus.aspx"
                                  target="_blank"
                                >
                                  Website
                                </a>{" "}
                                To know the status of application you may
                                use our WhatsApp bot based services on
                                0120-6226666 or write to us at
                                crm@noidapower.com or visit us at our Customer
                                Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.How to apply for refunding of security fund
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. You may visit our Customer Care Office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday
                                to apply for security refund, please follow the
                                link to know {" "}
                                <a href="https://www.noidapower.com/Document-Checklist">
                                  documentation requirement
                                </a>{" "}
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Paper Formalities Required for Load
                                  Reduction?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumer has to fill application and
                                provide all the requisite documentation which
                                are mentioned on the back side of application
                                form. Please follow the link to know
                                documentation requirement. Please note for load
                                reduction consumer can only apply after two
                                years from date of energization. For Temp.
                                Supply, consumer can apply for load reduction at
                                any point of time.
                              </p>
                              <p className="card_cont">
                                Q.How can consumer find the status of request
                                against New Connection?
                              </p>
                              <p className="card_cont">
                                Ans. To know the status of a New Connection
                                request, consumer may use following options.
                              </p>
                              <ul>
                                <li>
                                  Use our WhatsApp Bot based services on
                                  0120-6226666 or
                                </li>
                                <li>Can write us at crm@noidapower.com</li>
                                <li>
                                  Visit us at our Customer Care Office,{" "}
                                  <a
                                    href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                    target="_blank"
                                  >
                                    Knowledge Park-I
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                    target="_blank"
                                  >
                                    Techzone-IV
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                    target="_blank"
                                  >
                                    Sigma-IV:- 33/11KV Substation
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                    target="_blank"
                                  >
                                    Ecotech-II: Electric Sub-Station
                                  </a>{" "}
                                  Timings 10:00 am to 5:00 pm , Monday to
                                  Friday.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>Q.Estimate not Received Yet</span>
                              </p>
                              <p className="card_cont">
                                Ans. In case you have not received any update
                                after 7-10 days of applying for request.To know
                                the status of application you may WhatsApp Bot
                                based services on 0120-6226666 or can write us
                                at crm@noidapower.com or visit us at our
                                Customer Care Oﬃce,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What is minimum charges &amp; why it is to
                                  be paid.?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumer has to bear the minimum charges
                                based on the total sanctioned load &amp; as per
                                the applicable tariff rate it is been calculated
                                for remaining Tenure in case you have not
                                completed 2 yrs. to the connection or recent
                                load enhancement/reduction process. In Case the
                                maximum demand recorded in any of the last 2
                                billing cycles is higher than the reduced
                                contracted load consumer can not opt for the
                                proposed contracted load
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What is Augmentation? How much time it take
                                  to complete?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Augmentation means improvement in load
                                capacity as per requirement of installed
                                electrical equipments. Load is increased within
                                30 days after payment of estimate if no change
                                in electrical network is required .
                              </p>

                              <p className="card_cont">
                                <span>
                                  Q.How and where to approach for Shifting of
                                  Mains ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. We accept application for following request
                              </p>
                              <ul>
                                <li>Meter Shifting</li>
                                <li>Pole Shifting</li>
                                <li>Service Cable Shifting</li>
                                <li>Transformer Shifting</li>
                                <li>Penal Feeder Box Shifting</li>
                              </ul>
                              <p className="card_cont">
                                For the shifting related request an application
                                along with the copy of id proof and last paid
                                bill is required please follow the link to be
                                submitted at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}. Timings 10:00 am to 5:00
                                pm , Monday to Friday. After verification of
                                documents, site visit is arranged to check the
                                feasibility to fulfil the request and then a
                                survey report is prepared and accordingly
                                estimate is generated as per Regulatory
                                guidelines. Once the payment of estimate is done
                                then shifting will be executed
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What Documents are required for Load
                                  Enhancement/ Load Reduction/ Name Change/
                                  Category Change?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Please <a href="#">click Here.</a>
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What is the TAT for completion of Service
                                  Application?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of estimate payment against
                                your Service request it will take next billing
                                cycle to get implemented in the system during
                                this period you may receive 1 bill with previous
                                load/ category, except shifting of mains , as it
                                depends upon site condition and availability of
                                material.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Where we can contact in case of any
                                  information required for service request.?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. To know the status of application, use our
                                WhatsApp Bot based services on 0120-6226666 or
                                write to us at crm@noidapower.com or visit us at
                                our Customer Care Oﬃce,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What are the payment options for estimate
                                  payment ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumer can make online payment thru
                                NPCL website,details are available under
                                Payment options tab {" "}
                                <a
                                  href="https://www.noidapower.com/cash-collection-centres"
                                  target="_blank"
                                >
                                  https://www.noidapower.com/cash-collection-centres
                                </a>{" "}, excluding cheqeue payment, you may
                                opt any of the option to make estimate payment
                                or you may visit our Customer Care office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday
                                to apply in person for payment of estimate.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What is the basis of calculation of Security
                                  Deposit?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. The security deposit maintainable for a
                                consumer is equivalent to 2 times the average
                                bill value for the ﬁnancial year under
                                reference. For a new applicant, the security
                                deposit will be determined on the basis of load
                                applied.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my application is been Pending since
                                  long.?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Once you complete the documentation
                                process, the application goes for several checks
                                via different departments during which it might
                                have not been cleared under LCC/CF Check, To
                                know the status of application use our WhatsApp
                                Bot based services on 0120-6226666 or write to
                                us at crm@noidapower.com or visit us at our
                                Customer Care Oﬃce,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Where to contact in case of application
                                  rejected due to LCC/Theft?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. You may visit Our Office at {" "}
                                <a
                                  href="https://maps.app.goo.gl/Wnu4DU1kBUuuotLe6"
                                  target="_blank"
                                >
                                  Knowledge Park-2
                                </a>{" "}
                                Timings 10:00 am to 3:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.When am I required to pay Additional
                                  Security Deposit (ASD)?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Each year the security deposit is reviewed
                                in the month of April on the basis of last one
                                year consumption. The statement of Security
                                Deposit account is given in the bill for the
                                month of April. If the maintainable Security
                                Deposit is more than the security Deposit “held”
                                amount, additional security deposit is required
                                to be paid.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What are the documents required in case of
                                  Small Correction in Connection Details such as
                                  Spelling of Name, inadequate Address?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. The documentary proof for the desired
                                change along with latest paid bill receipt along
                                with correction form by the property owner. You
                                may visit Customer Care Office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                . Timings 10:00 am to 5:00
                                pm , Monday to Friday
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What happens if the Security Deposit amount
                                  held is more than the Security Deposit
                                  Maintainable amount?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. In the above scenario, no ASD request will
                                be placed however, as per the RBI guidelines
                                interest gets credited for the held security
                                amount.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.How do I get refund of Security Deposit
                                  after account closure?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. After necessary adjustment, the Security
                                Deposit amount held with us is refunded with an
                                A/c payee cheque in the name of the registered
                                consumer at the recorded address.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.When I can apply for Security Refund
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Consumer may apply for Security Refund in
                                below given scenarios :
                              </p>
                              <ul>
                                <li>
                                  *Once the connection is Permanently
                                  Disconnected
                                </li>
                                <li>
                                  * Once the property is sold and also name
                                  mutation has been completed in records and
                                  earlier connection gets permanently
                                  disconnected, the Old/Earlier property holder
                                  may apply for Security Refund
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.What are the documents required for Security
                                  Refund
                                </span>
                              </p>
                              <p className="card_cont">
                                <a href="#">Click here</a>
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Who is eligible to get Security Refund
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Only the registered consumer or Old owner
                                (in case of property sold out) can apply for
                                Security Refund
                              </p>
                              <p className="card_cont">
                                <span>Q.Can I get Final Bill ?</span>
                              </p>
                              <p className="card_cont">
                                Ans. After meter removal from site final bill
                                get generated within 15 days and final copy may
                                be provided wherein consumer himself/herself,
                                have requested for permanent disconnection and
                                having relevant notification no. too.
                              </p>
                              <p className="card_cont">
                                <span>Q.How to apply for NOC ?</span>
                              </p>
                              <p className="card_cont">
                                Ans. Once you get the final bill copy and upon
                                making necessary bill payments (if any),
                                consumer may apply for NOC from our customer
                                care office at{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}.
                                Timings 10:00 am to 5:00 pm , Monday to
                                Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why My New Connection service request is
                                  pending for Site Visit ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Please note that after completion of
                                documentation process, the application goes for
                                several checks via different departments during
                                which it might have not been cleared under
                                LCC/CF Check. After necessary clearance from
                                respective team, you shall be notified with the
                                Estimate Amount which is to paid before
                                executing the site work. In case, payment is
                                been done and work still pending. To know the
                                status of a New Connection request, consumer may
                                use following options.
                              </p>
                              <ul>
                                <li>
                                  You may Use our WhatsApp Bot based services on
                                  0120-6226666
                                </li>
                                <li>Can write us at crm@noidapower.com</li>
                                <li>
                                  Visit us at our Customer Care Office,{" "}
                                  <a
                                    href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                    target="_blank"
                                  >
                                    Knowledge Park-I
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                    target="_blank"
                                  >
                                    Techzone-IV
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                    target="_blank"
                                  >
                                    Sigma-IV:- 33/11KV Substation
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                    target="_blank"
                                  >
                                    Ecotech-II: Electric Sub-Station
                                  </a>{" "}
                                  Timings 10:00 am to 5:00 pm , Monday to
                                  Friday.
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q.Why Estimate not yet generated for My New
                                  Connection service request?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of notification generation
                                against your Service request it will take 7-10
                                days time for document verification followed
                                with site visit &amp; after receiving site
                                report, Estimate get generated &amp; sent for
                                necessary approvals. We shall keep you posted
                                with SMS/EMail alert containing payment link of
                                estimate.In Case you have received any rejection
                                SMS/Email on registered contact details, you may
                                reach out to us via our WhatsApp Bot based
                                services on 0120-6226666. For any further
                                requirement, you may please visit us at our
                                Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.I have paid my estimate agaist new service
                                  request than why meter installation is still
                                  pending ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of Estimate payment against
                                your Service request it will take 7-10 days time
                                for meter installation. Only if there is any
                                right of way issue, it may take more time for
                                completing the assigned work and it may only be
                                completed once clear way will be provided by
                                consumer to our service team. Use our WhatsApp
                                Bot based services on 0120-6226666. For any
                                further assistance, you may please visit us at
                                our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why Estimate not yet generated for My Name
                                  Mutation service request?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of notification generation
                                against your Service request it will take 7 days
                                time for document verification and necessary
                                approvals. In Case you have received any
                                rejection SMS/Email on registered contact
                                details, you may reach out to us via our
                                WhatsApp Bot based services on 0120-6226666. For
                                any further requirement, you may please visit us
                                at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my request for Name Mutation is still
                                  pending ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of Estimate Payment against
                                your Service request it will take next billing
                                cycle to get implemented in the system during
                                this period you may receive 1 bill with previous
                                details. In case of any doubt .For any further
                                requirement, you may please visit us at our
                                Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why Estimate not yet generated for My Load
                                  Augmentation service request?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of notification generation
                                against your Service request it will take 7-10
                                days time for document verification followed
                                with site visit &amp; after receiving site
                                report, Estimate get generated &amp; sent for
                                necessary approvals. We shall keep you posted
                                with SMS/EMail alert containing payment link of
                                estimate. In Case you have received any
                                rejection SMS/Email on registered contact
                                details, you may reach out to us via our
                                WhatsApp Bot based services on 0120-6226666. For
                                any further requirement, you may please visit us
                                at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why My Load Augmentation service request is
                                  pending for Site Visit ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Please note that after completion of
                                documentation process, the application goes for
                                several checks via different departments during
                                which it might have not been cleared under
                                LCC/CF Check. After necessary clearance from
                                respective team, you shall be notified with the
                                Estimate Amount which is to paid before
                                executing the site work. In case, payment is
                                been done and work still pending you may visit
                                us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.I have paid my estimate agaist Load
                                  Augmentation request than why meter
                                  installation is still pending ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of Estimate payment against
                                your Service request it will take 7-10 days time
                                for meter installation. Only if there is any
                                right of way issue, it may take more time for
                                completing the assigned work and it may only be
                                completed once clear way will be provided by
                                consumer to our service team. You may reach out
                                to us via our WhatsApp Bot based services on
                                0120-6226666. For any further assistance, you
                                may please visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my request for Category Change is still
                                  pending ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of Estimate Payment against
                                your Service request it will take next billing
                                cycle to get implemented in the system during
                                this period you may receive 1 bill with previous
                                details. In case of any doubt you may reach out
                                to us via our WhatsApp Bot based services on
                                0120-6226666. For any further requirement, you
                                may please visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why Estimate not yet generated for My
                                  Category Change service request?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of notification generation
                                against your Service request it will take 7 days
                                time for document verification and necessary
                                approvals. In Case you have received any
                                rejection SMS/Email on registered contact
                                details, you may reach out to us via our
                                WhatsApp Bot based services on 0120-6226666. For
                                any further requirement, you may please visit us
                                at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why Estimate not yet generated for My Load
                                  Reduction service request?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of notification generation
                                against your Service request it will take 7-10
                                days time for document verification followed
                                with site visit (As &amp; when required) &amp;
                                after receiving site report, Estimate get
                                generated &amp; sent for necessary approvals. We
                                shall keep you posted with SMS/EMail alert
                                containing payment link of estimate. In Case you
                                have received any rejection SMS/Email on
                                registered contact details, you may reach out to
                                us via our WhatsApp Bot based services on
                                0120-6226666. For any further requirement, you
                                may please visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my request for Load Reduction is still
                                  pending ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of Estimate Payment against
                                your Service request it will take next billing
                                cycle to get implemented in the billing, during
                                this period you may receive 1 bill with previous
                                load details. In case of any doubt you may reach
                                out to us via our WhatsApp Bot based services on
                                0120-6226666. For any further requirement, you
                                may please visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why My Shifting request is pending for Site
                                  Visit ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Please note that after completion of
                                documentation process, the application goes for
                                several checks via different departments during
                                which it might have not been cleared under
                                LCC/CF Check. After necessary clearance from
                                respective team, you shall be notified with the
                                Estimate Amount which is to paid before
                                executing the site work. In case, payment is
                                been done and work still pending you may please
                                visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                .Timings 10:00 am to 5:00
                                pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why Estimate not yet generated for My
                                  Shifting request?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. From the date of notification generation
                                against your Shifting request it will take 7-10
                                days time for document verification followed
                                with site visit &amp; after receiving site
                                report, Estimate get generated &amp; sent for
                                necessary approvals. We shall keep you posted
                                with SMS/EMail alert containing payment link of
                                estimate. In Case you have received any
                                rejection SMS/Email on registered contact
                                details, you may please visit us at our Customer
                                Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                .Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why there is no action taken at site ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Please note that after completion of
                                documentation process, the application goes for
                                several checks via different departments during
                                which it might have not been cleared under
                                LCC/CF Check. After necessary clearance from
                                respective team, you shall be notified with the
                                Estimate Amount which is to paid before
                                executing the siite work. In case, payment is
                                been done and work still pending you may please
                                visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}.
                                Timings 10:00 am to 5:00
                                pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my Name Correction request not
                                  done/completed ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Ans. From the date of notification
                                generation against your Service request it will
                                take next billing cycle to get implemented in
                                the system during this period you may receive 1
                                bill with previous details. In case of any doubt
                                you may reach out to us via our Whatsapp
                                services on 0120-6226666 NAC{" "}
                                <space>
                                  {" "}
                                  Notification No. For any further requirement,
                                  you may please visit us at our Customer Care
                                  Office,{" "}
                                  <a
                                    href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                    target="_blank"
                                  >
                                    Knowledge Park-I
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                    target="_blank"
                                  >
                                    Techzone-IV
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                    target="_blank"
                                  >
                                    Sigma-IV:- 33/11KV Substation
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                    target="_blank"
                                  >
                                    Ecotech-II: Electric Sub-Station
                                  </a>{" "}
                                  Timings 10:00 am to 5:00 pm , Monday to
                                  Friday.
                                </space>
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my Name correction is still pending
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Once you complete the documentation
                                process, the application goes for several checks
                                via different departments during which it might
                                have not been cleared under LCC/CF Check, you
                                may please visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my Address Correction request not
                                  done/completed ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Ans. From the date of notification
                                generation against your Service request it will
                                take next billing cycle to get implemented in
                                the system during this period you may receive 1
                                bill with previous details. In case of any doubt
                                you may reach out to us via our Whatsapp
                                services on 0120-6226666 NAC{" "}
                                <space>
                                  {" "}
                                  Notification No. For any further requirement,
                                  you may please visit us at our Customer Care
                                  Office,{" "}
                                  <a
                                    href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                    target="_blank"
                                  >
                                    Knowledge Park-I
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                    target="_blank"
                                  >
                                    Techzone-IV
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                    target="_blank"
                                  >
                                    Sigma-IV:- 33/11KV Substation
                                  </a>{" "}
                                  {" "}
                                  <a
                                    href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                    target="_blank"
                                  >
                                    Ecotech-II: Electric Sub-Station
                                  </a>{" "}
                                  Timings 10:00 am to 5:00 pm , Monday to
                                  Friday.
                                </space>
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Why my Address Correction is still pending
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Once you complete the documentation
                                process, the application goes for several checks
                                via different departments during which it might
                                have not been cleared under LCC/CF Check, you
                                may please visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}
                                Timings 10:00 am to 5:00 pm , Monday to Friday.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Can I apply new connections floor wise?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. In domestic high rise building electricity
                                connection to individual owner/occupant are
                                provided on multipoint scheme as per the
                                guidelines of 13th Ammendmant of Electricity
                                Supply Code, 2005.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What should be the file type and size to
                                  upload for new connection online?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. File size can't exceed 15 mb per
                                documentation type.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Can I apply new connection through mobile
                                  app?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Yes, you can apply new connection from NPCL
                                mobile app.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.How much I have to pay for Prepaid
                                  Connection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. You shall have to pay the applicable
                                processing fee, service charges and security (if
                                applicable) as specified in Cost Data Book which
                                shall be calculated based upon your application
                                and site survey.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Who can apply for Temporary Connection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. If you are intending to carry out any
                                construction work at your premise and seeking of
                                electricity connection for construction
                                activity, then, you will have to apply for
                                temorary connection.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What are the Documentation requirement for
                                  Temporary Connection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Applicant has to fill application form for
                                new connection and provide all the requisite
                                documentation which are mentioned on the back
                                side of new connection application form.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.How to convert from temporary to permanent?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Once you complete construction work and
                                obtains building completion certificate from
                                concerned authority i.e. GNIDA/UPSIDC, you can
                                apply for Permanent connection.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What are the charges of temporary
                                  connection?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. You shall have to pay the applicable
                                processing fee, security deposit and service
                                charges as specified in Cost Data Book which
                                shall be calculated based upon your application
                                and site survey
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.What is B&amp;L form and why it is required
                                  in load augmentation?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. B&amp;L form or Work completion certificate
                                form is filled by the licensed electrical
                                contractor who has carried out electrical
                                wiring/installation of premise. This certifies
                                that electrical wiring/installation is done
                                following all the regualtions and includes the
                                equipments details installed, insulation test
                                result of the wiring/installation.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q.Whom to contact for status of Online New
                                  Connection application
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. To know the status of application you may
                                use our WhatsApp bot based services on
                                0120-6226666 or can write us at crm@noidapower.com
                                or visit us at our Customer Care Office,{" "}
                                <a
                                  href="https://goo.gl/maps/rQGWf9VtcXMy39Py9"
                                  target="_blank"
                                >
                                  Knowledge Park-I
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://goo.gl/maps/drZnuHVzsjNxxHLR7"
                                  target="_blank"
                                >
                                  Techzone-IV
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/WGGWJ4HRFUbxDxz76"
                                  target="_blank"
                                >
                                  Sigma-IV:- 33/11KV Substation
                                </a>{" "}
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/2dMiXX2K9mKefv5V6"
                                  target="_blank"
                                >
                                  Ecotech-II: Electric Sub-Station
                                </a>{" "}. Timings 10:00 am to 5:00
                                pm , Monday to Friday.
                              </p>
                            </div>
                          ) : null}
                        </div>

                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow(!show)}
                          >
                            <b>Supply Related</b>
                          </div>

                          {show ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q. Where to complaint for No-Power issue?
                                </span>
                              </p>
                              <p className="card_cont">
                                Register the complaint at NPCL's helpline no.
                                +91-0120 6226666 along with 10-digit consumer
                                number printed at the upper left corner of your
                                electricity bill and after registration of
                                complaint one complaint number will be intimated
                                to you for future reference. NPCL
                                fault-repairing crew will attend to the cause of
                                the complaint and resolve the same within the
                                following time limits:
                              </p>
                              <ul>
                                <li>Normal Fuse-off: Within 2 Hrs</li>
                                <li>Overhead Line Breakdowns: Within 2 Hrs</li>
                                <li>
                                  Underground Line Breakdowns: Within 12 Hrs
                                </li>
                                <li>
                                  Distribution Transformer failure: Within 6 Hrs
                                </li>
                              </ul>
                              <p className="card_cont">
                                You may reach out us through our WhatsApp Bot
                                based services on 0120-6226666 or Send SMS at
                                7840002288 ( #NoPower {"<Space>"} Consumer No.)
                                May also download our mobile app for easy &
                                quick access.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How to address the problem of frequent
                                  tripping of MCB or blowing of fuse?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Contact a Licensed Electrical contractor
                                (L.E.C.) to locate the cause. If there is a
                                fault/defect in your wiring installations,
                                rectify the same.
                              </p>
                              <p className="card_cont">
                                If the load of your installation is more than
                                the load allowed by NPCL, please apply for
                                enhancement of load by filling up a form
                                available at NPCL office KP-1 &amp; know more
                                about the same.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. How to resolve supply-related complaints on
                                  power interruptions or unsatisfactory voltage
                                  profile ?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Register the complaint at NPCL's helpline
                                no. +91-0120 6226666 along with
                                10-digit consumer number printed at the upper
                                left corner of your electricity bill and one
                                complaint number will be intimated to you for
                                future reference. NPCL fault-repairing crew will
                                attend to the cause of the complaint and resolve
                                the same within the following time limits:
                              </p>
                              <ul>
                                <li>Local problem on transformer : 2 days</li>
                                <li>no expansion required: 10 days</li>
                                <li>
                                  upgradation of distribution box : 120 days
                                </li>
                              </ul>
                              <p className="card_cont">
                                You may reach out us through our WhatsApp Bot
                                based services on 0120-6226666 or Send SMS at
                                7840002288 ( #NOPOWER {"<Space>"} Consumer No.)
                              </p>
                            </div>
                          ) : null}
                        </div>
                        <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow10(!show10)}
                          >
                            <b>Peer To Peer Energy Trading</b>
                          </div>
                          {show10 ? (
                            <div className="card-body">
                              <p className="card_cont">
                                <span>
                                  Q. What is P2P solar trading Platform?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. P2P (Peer-to-Peer) solar trading platform is a blockchain-based system that enables prosumers (individuals generating solar energy) to sell their excess solar power directly to P2P consumers at mutually agreed prices.
                              </p>

                              <p className="card_cont">
                                <span>
                                  Q. Who are the Participants in solar energy trading?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Participants in solar energy trading include prosumers and consumers who have registered with the NPCL and the P2P platform. Prosumers generate and sell rooftop solar energy, while consumers purchase this energy through the P2P platform. Both parties engage in transactions facilitated by the platform.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Who are Consumer and Prosumer?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Prosumer -A consumer of NPCL who owns solar power generation panel and is registered with the NPCL to sell its solar energy generated through rooftop solar under UPERC (Rooftop Solar PV Grid Interactive System Gross /Net Metering) Regulations, 2019 on P2P platform.
                              </p>
                              <p className="card_cont">
                                Consumer -A consumer of NPCL who is registered with the NPCL to buy solar energy through P2P platform from a P2P prosumer.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Who can participate in solar energy trading?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Participants who are registered with the NPCL and P2P Platform.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Who are participants?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. A prosumer or a consumer who has registered with the NPCL and Service Provider to sell or buy rooftop solar energy through P2P platform provided by the Service Provider.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Who is service provider?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. An agency who registers itself with NPCL to provide P2P solar energy transaction services on blockchain based P2P Platform.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What are the eligibility criteria for participation in energy trading?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. To participate in energy trading, the following criteria must be met for both prosumers and consumers:
                              </p>
                              <p className="card_cont">
                              Prosumer: The prosumer must have a solar generation panel installed.
                              </p>
                              <p className="card_cont">General Eligibility for Both Prosumers and Consumers:</p>
                              <p className="card_cont">
                              <ol type="a">
                                <li>Both prosumers and consumers must be live, consumer of NPCL.</li>
                                <li>Both must have cleared all outstanding dues with NPCL.</li>
                              </ol></p>
                              <p className="card_cont">
                                <span>
                                  Q. What is the Transaction Price?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Transaction Price is a mutually agreed price between P2P Prosumers and P2P Consumers, for energy transacted on P2P platform.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. What are the products offered?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans.  <ol type="a">
                                  <li> Intraday Market
                                    <p>
                                    For intraday transactions, participants submit their schedule energy to be transacted on P2P platform at least four-time blocks before the commencement of schedule. No deviation in schedule shall be allowed beyond this point.
                                    </p>
                                  </li>
                                  <li> Day-Ahead
                                    <p>
                                    For day ahead transactions, participants shall submit their schedule for the energy to be transacted on P2P platform on nth day, by 1700 hrs of (n-l)th day. No deviation in schedule shall be allowed beyond this time.
                                    </p>
                                  </li>
                                </ol>
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Is there Transaction charges on solar energy trading?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans.
                              </p>
                              <ul>
                                <li>Fees charged for P2P solar energy transaction on P2P platform, as specified by the H’ble UPERC (Presently it has been taken as Rs. 0.42 (incl. of GST) per unit, thereby levying @ Rs. 0.21 on P2P Prosumer and P2P Consumer both).
                                </li>
                                <li>Wheeling charges will be as per the applicable H’ble UPERC Tariff Order i.e. Rs. 0.77 per unit (Wheeling charges is applicable for Consumers only.)
                                </li>
                              </ul>
                              <p className="card_cont">
                                <span>
                                  Q. Is there a mobile application available for P2P Platform?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Yes, currently only the android app is available which can be downloaded from the  P2P Google Play Store application, simply visit the Google Play Store if you are using an Android phone and search for the NPCL SAUR MITRA.
                              </p>
                              <p className="card_cont">
                                <span>
                                  Q. Help-desk queries?
                                </span>
                              </p>
                              <p className="card_cont">
                                Ans. Help desk can address all questions, and clarifications related to the P2P platform
                              </p>
                              <ul>
                                <li>For registration, please visit: https://www.noidapower.com/</li>
                                <li>For any query, you can reach out to us via email at: CRM@noidapower.com</li>
                                <li>If you prefer to call us, our help line is available at: 0120 6226666 (24 x 7)</li>
                              </ul>
                              <p className="card_cont">
                                "Currently P2P related complaints are only handled by the above mentioned medium".
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Faq;
