import React, { useEffect } from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

const RoofTopSolar = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background:
                "url(" + "assets/img/inner/solar-banner-main-1.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                      Customer Service
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div only-my-class-style-additional-step-2">
                      <h3>Rooftop Solar</h3>
                      <p>
                        Rooftop solar is a photovoltaic system that generates
                        electricity from solar panels mounted on the rooftop of
                        any building like
                        residential/Institutional/Commercial/Industrial. Various
                        components of such system include photovoltaic modules,
                        mounting systems, cables, solar inverters and other
                        electrical accessories.
                      </p>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <h4>Net Metering</h4>
                          <p>
                            Net Metering Scheme means the metered prosumers of a
                            Licensee under domestic under (LMV-1) category or
                            agriculture (LMV-5) category, who intends to/ has
                            set up a grid connected rooftop solar PV system in
                            the consumer premises, which can be self-owned or
                            third party owned wherein the energy imported from
                            the grid and the energy exported to the grid
                            interactive rooftop solar PV system are netted out
                            through single bi-directional energy meter.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="only-my-class-style-additional-img-step-1">
                            <img
                              src="assets/img/inner/solarto-1.jpg"
                              className="img-responsive" alt="solar"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="only-my-class-style-additional-text-step-1">
                            <p>
                              Net metering is a billing mechanism that credits
                              solar energy system owner for the electricity they
                              add to the grid. The energy generated by the Grid
                              Interactive Rooftop Solar Photovoltaic (RSPV)
                              system is first used for self-consumption and the
                              excess energy is injected / exported to the grid
                              automatically. For example, if a residential
                              customer has a PV system on its roof-top, it may
                              generate more electricity than his current usage
                              of the home and the unused / excess energy flows
                              to the grid.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-30">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="only-my-class-style-additional-text-step-1">
                            <p>
                              Through Net metering arrangement by using Single
                              Bi-Directional Energy Meter, solar energy exported
                              to the Grid from Grid Interactive RSPV system is
                              deducted from energy imported from the Grid in
                              units (kWh) to arrive at the net imported or
                              exported energy and the net energy import or
                              export is billed or credited or carried-over on
                              the basis of the applicable retail tariff. At the
                              end of the each settlement period (Financial
                              year), any electricity credits, which remain
                              unadjusted shall be paid at a rate of Rs.2/kWH by
                              NPCL. At the beginning of next settlement period,
                              cumulative carried over electricity credits shall
                              be reset to Zero.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="only-my-class-style-additional-img-step-1">
                            <img
                              src="assets/img/inner/solarto-2.jpg"
                              className="img-responsive" alt="solar-rooftop"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-30">
                          <p>
                            The Peak rated capacity (kWp) of Grid Interactive
                            Rooftop Solar Photovoltaic (RSPV) system to be
                            installed by any eligible consumer shall not exceed
                            100% of the sanctioned/contractual load of the
                            building/consumer as per UPERC RSPV Regulation 2019.
                            This means, if a consumer has sanctioned/contractual
                            load of 5 kW, the maximum RSPV system that can be
                            installed is 5 kWp.
                          </p>
                        </div>
                      </div>
                      <div className="row mt-30">
                        <h3>Net Billing/Net Feed In Tariff:</h3>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <p>
                            Net-billing / net feed-in scheme means a prosumer of
                            any category, in the area of supply of the
                            Distribution Licensee, who intends to set up a grid
                            connected rooftop solar PV system in his/ her
                            premises, which can be self-owned or third party
                            owned, where-in the energy imported from the Grid
                            and energy exported to the Grid Interactive rooftop
                            Solar photovoltaic system of a Prosumer are measured
                            through a single bi-directional energy meter valued
                            at two different Tariffs which are determined by the
                            Commission.
                          </p>
                        </div>
                      </div>
                      <div className="row mt-30">
                        <h3>Benefits of Rooftop Solar Net Metering:</h3>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="only-my-class-style-additional-text-step-1">
                            <ul className="underline-style-step-1">
                              <li>Reduced Power Bills</li>
                              <li>Low Maintenance</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="only-my-class-style-additional-text-step-1">
                            <ul className="underline-style-step-1">
                              <li>One Time Investment</li>
                              <li>Clean & Green Power</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <p>
                            NPCL has taken huge leap towards promoting roof top
                            solar power. Get a solar panel installed to enjoy
                            clean and affordable power with savings at every
                            step.
                          </p>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-30">
                          <h3>
                            Procedure for Net Metering and Net Billing/Net Feed
                            In Application at NPCL:
                          </h3>
                          <p>
                            As per UPERC RSPV Regulation 2019, Domestic
                            Consumers (LMV-1) & Agriculture (LMV-5) are only
                            eligible for Net Metering and all tariff category
                            are eligible for Net Billing/Net Feed In scheme
                            only. For On Grid Rooftop Solar Photovoltaic
                            installation, MNRE is giving Central Finance
                            Assistance (CFA) for the Net metered
                            domestic/residential consumers only. To avail CFA /
                            Subsidy from State & Central Govt. Under Phase-II of
                            Roof Top Solar Scheme of MNRE, it is mandatory to
                            install RSPV System by empanelled vendors of UPNEDA
                            only. Consumers interested to install RSPV System
                            shall be required to apply on the National portal
                            (https://solarrooftop.gov.in) for the subsidy based
                            RTS project and thereafter, Consumer / Solar Vendor
                            shall submit hard copies of all other relevant
                            documents as per document requirement list at NPCL
                            Office at Sector Knowledge Park-3, Greater Noida.
                          </p>
                        </div>
                      </div>
                      <div className="row mt-30">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="wrapper-rooftop-main-style-step-1">
                            <ul>
                              <li style={{ background: "#eee" }}>
                                <a
                                  href="https://iwebapps.noidapower.com:8032/download/Net_Metering_Document_Requirement_List_for_website.pdf"
                                  target="_blank"  rel="noreferrer"
                                >
                                  Document required for Net Metering
                                  application:
                                </a>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <a
                                  href="https://iwebapps.noidapower.com:8032/download/Net_Billing_Document_Requirement_List-For_Website.pdf"
                                  target="_blank"  rel="noreferrer"
                                >
                                  Document required for Net Billing/Net Feed-In
                                  application:
                                </a>
                              </li>
                            </ul>

                            <ul>
                              <li style={{ background: "#eee" }}>
                                Apply for solar rooftop
                              </li>
                            </ul>

                            <ul>
                              <li>
                                a. For Subsidy based project apply on <strong> National Portal for Rooftop Solar </strong> (Only Residential Consumer):
                                {" "} <a
                                  href="https://pmsuryaghar.gov.in/consumerLogin"
                                  target="_blank"  rel="noreferrer"
                                >
                                  {" "}
                                  Click Here.
                                 
                                
                                </a>
                               <br />
                                Note:  {" "} <a
                                  href="https://iwebapps.noidapower.com:8032/download/Stepwise_procedure_PM_Surya_Ghar_Portal.pdf"
                                  target="_blank"  rel="noreferrer"> Stepwise Procedure for National Portal   </a>
                              </li>
                              <li>
                                b.  Non-Subsidy Based project: Directly submit document at below mentioned address.
                              </li>
                            </ul>

                          

                            <ul>
                              <li>DISCOM Nodal Officer</li>
                            </ul>

                           
                            <div className="row panel">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Table stripped bordered hover size="sm">
                                  <thead>
                                    <tr>
                                      <th width="170">Name</th>
                                      <th width="170">E -mail ID</th>
                                      <th width="170">Contact Number</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Shri Sarnath Ganguly</td>
                                      <td>
                                        {/* <a
                                          href="mailto:rooftopsolar@noidapower.com"
                                          style={{ textDecoration: "none" }}
                                        > */}
                                          rooftopsolar@noidapower.com
                                        {/* </a> */}
                                      </td>
                                      <td>
                                        <a
                                          href="tel:0120 6226606"
                                          style={{ textDecoration: "none" }}
                                        >
                                          0120 6226606
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Shri Sanket Shrivastava</td>
                                      <td>
                                        {/* <a
                                          href="mailto:rooftopsolar@noidapower.com"
                                          style={{ textDecoration: "none" }}
                                        > */}
                                          rooftopsolar@noidapower.com
                                        {/* </a> */}
                                      </td>
                                      <td>
                                        <a
                                          href="tel:0120 6226601"
                                          style={{ textDecoration: "none" }}
                                        >
                                          0120 6226601
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Shri Prosenjit Das</td>
                                      <td>
                                        {/* <a
                                          href="mailto:rooftopsolar@noidapower.com"
                                          style={{ textDecoration: "none" }}
                                        > */}
                                          rooftopsolar@noidapower.com
                                        {/* </a> */}
                                      </td>
                                      <td>
                                        <a
                                          href="tel:0120 2333520"
                                          style={{ textDecoration: "none" }}
                                        >
                                          0120 6226620
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>

                            <ul>
                              <li>
                              <br/>
                                <strong> Subsidy Information: </strong>
                                <hr />
                                
                                 a. <strong>Central Finance Assistance (CFA)/Central Govt Subsidy </strong> for residential rooftop solar installation:  <a
                                  href="https://iwebapps.noidapower.com:8032/download/PM_Surya_Ghar_CFA_Structure.pdf"
                                  target="_blank"  rel="noreferrer"
                                > Click here 
                                </a>


                              </li>
                              <li>
                              b. <strong>State Govt. Subsidy </strong> Addition to the Central Finance Assistance (CFA), to promote installation of Grid connected Rooftop systems under Net-Metering arrangement on large scale in private residential sectors, State Government will provide subsidy of Rs 15000/KW to a maximum limit of subsidy Rs 30000/- per consumer. This Subsidy will be disbursed by UPNEDA to beneficiary on reimbursement basis after successful installation and commissioning of Rooftop solar systems with net metering and submission of all documents.
                              </li>
                            </ul>

                            <ul>
                              <li style={{ background: "#eee" }}>
                                <a
                                  href="http://upnedasolarrooftopportal.com/Approved-Firms"
                                  target="_blank"  rel="noreferrer"
                                >
                                  List of vendors empanelled and their contact
                                  details.
                                </a>
                              </li>
                            </ul>

                            <ul>
                              <li>
                                <a
                                  href="https://solarrooftop.gov.in/rooftop_calculator"
                                  target="_blank"  rel="noreferrer"
                                >
                                  Solar rooftop calculator.
                                </a>
                              </li>
                            </ul>

                            <ul>
                              <li style={{ background: "#eee" }}>
                                <a
                                  href="https://iwebapps.noidapower.com:8032/download/Responsibilities_of_Consumer_Discoms.pdf"
                                  target="_blank"  rel="noreferrer"
                                >
                                  Responsibility of consumers & DISCOMs.
                                </a>
                              </li>
                            </ul>

                            <ul>
                              <li>Solar Roof Top Portal of MNRE / UPNEDA.</li>
                            </ul>

                            <ul>
                              <li style={{ background: "#eee" }}>
                                <a href="https://mnre.gov.in/" target="_blank"  rel="noreferrer">
                                  https://mnre.gov.in/ 
                                </a>
                              </li>
                            </ul>

                            <ul>
                              <li>
                                <a
                                  href="https://solarrooftop.gov.in/"
                                  target="_blank"  rel="noreferrer"
                                >
                                  https://solarrooftop.gov.in/
                                </a>
                              </li>
                            </ul>

                            <ul>
                              <li style={{ background: "#eee" }}>
                                <a
                                  href="http://upnedasolarrooftopportal.com/"
                                  target="_blank"  rel="noreferrer"
                                >
                                  http://upnedasolarrooftopportal.com/
                                </a>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <a href="http://upneda.org.in/" target="_blank"  rel="noreferrer">
                                  http://upneda.org.in/
                                </a>
                              </li>
                            </ul>
                            <ul>
                              <li style={{ background: "#eee" }}>
                                <a
                                  href="https://www.uperc.org/"
                                  target="_blank"  rel="noreferrer"
                                >
                                  https://www.uperc.org/
                                </a>
                              </li>
                            </ul>

                            <ul>
                              <li>
                                Total Solar Rooftop Net Metering capacity in
                                NPCL is 31.332MW as on 31st March 2024.
                              </li>
                            </ul>

                            <ul>
                              <li>
                                Net Metering/Net Billing/Net Feed In Applicable
                                Charges click Here
                                <a
                                  href="https://iwebapps.noidapower.com:8032/download/Net_Metering_Applicable_Charges.pdf"
                                  target="_blank"  rel="noreferrer"
                                >
                                  {" "}
                                  click Here
                                </a>
                              </li>
                            </ul>
                            <ul>
                              <li style={{ background: "#eee" }}>
                                For further queries the below official may be
                                contacted:
                              </li>
                            </ul>
                            <div class="row panel">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Table stripped bordered hover size="sm">
                                  <thead>
                                    <tr>
                                      <th width="170">Name</th>
                                      <th width="170">E -mail ID</th>
                                      <th width="170">Contact Number</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Mr.Prosenjit Das</td>
                                      <td>
                                        <a style={{ textDecoration: "none" }}>
                                          rooftopsolar@noidapower.com
                                        </a>
                                      </td>
                                      <td>
                                        <a style={{ textDecoration: "none" }}>
                                          01206226606 / 9911998252
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>

                                <p>
                                  <strong>Office Address:</strong>
                                  <br />
                                  Noida Power Company Limited
                                  <br />
                                  33/11 KV Sub-station,
                                  <br />
                                  Knowledge Park – III,
                                  <br />
                                  Greater Noida-201310
                                  <br />
                                  Contact No.: <a>01206226606</a> (From 10.00AM
                                  to 5.00PM, Monday to Friday)
                                  <br />
                                  Email ID: <a>rooftopsolar@noidapower.com</a>
                                </p>
                              </div>
                            </div>
                            <ul>
                              <li style={{ background: "#eee" }}>
                                To Contact Online Please{" "}
                                <a
                                  href="https://iwebapps.noidapower.com:8032/solarRoofAdddr.aspx"
                                  target="_blank"  rel="noreferrer"
                                >
                                  {" "}
                                  click Here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default RoofTopSolar;
