import React from 'react'
import ReactDom from 'react-dom'
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";

const Modal  = props => {
    const histor = useHistory(); 

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed:3000,
    };

    const closeFunction = ()=>{
        histor.push("/");
        document.getElementById("main").style.display = "none";
    }
    return ReactDom.createPortal(
        <div id="main">
        <div id="pop-up">
          <button id='close-btn' onClick={()=>closeFunction()} style={{right:"-19px"}}>X</button>
          <Slider
                            ref={(slider) => (slider = slider)}
                            {...settings}
                          >
                            {/* <div className="csrslider" >
                            <a>
                              <img src="https://iwebapps.noidapower.com:8032/PopUp/BannerSM.png" style={{objectFit:"cover"}}/>
                             </a>
                            </div> */}
                            <div className="csrslider">
                            <a href="https://iwebapps.noidapower.com:8032/Authenticate/Login.aspx" target="_blank" >
                              <img src="https://iwebapps.noidapower.com:8032/PopUp/bannerp2p2.jpeg" style={{objectFit:"cover"}}/>
                              </a>
                            </div>
                            <div className="csrslider" >
                            <a href="https://pmsuryaghar.gov.in/consumerLogin" target="_blank" >
                              <img src="https://iwebapps.noidapower.com:8032/PopUp/img1.jpg" style={{objectFit:"cover"}}/>
                             </a>
                            </div>
                            <div className="csrslider">
                            <a href="https://eeslmart.in/?affiliateid=npcl" target="_blank" >
                              <img src="https://iwebapps.noidapower.com:8032/PopUp/img4.jpg" style={{objectFit:"cover"}}/>
                              </a>
                            </div>

           </Slider>
        </div>
      </div>,document.querySelector('#modal')
    )
}

export default Modal