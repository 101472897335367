import { Link } from "react-router-dom";
import React, { useState } from "react";
//import React from "react";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";

const Tender = () => {
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
   const [show2, setShow2] = useState(false);
   const [show3, setShow3] = useState(false);
   const [show4, setShow4] = useState(false);
   const [show5, setShow5] = useState(false);
   const [show6, setShow6] = useState(false);
   const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const [show11, setShow11] = useState(false);
  const [show12, setShow12] = useState(false);
  const [show13, setShow13] = useState(false);
  const [show14, setShow14] = useState(false);
  const [show15, setShow15] = useState(false);
  const [show16, setShow16] = useState(false);
  const [show17, setShow17] = useState(false);
 const [show18, setShow18] = useState(false);
 const [show19, setShow19] = useState(false);
 const [show20, setShow20] = useState(false);
 const [show21, setShow21] = useState(false);
 const [show22, setShow22] = useState(false);
 const [show23, setShow23] = useState(false);
 const [show24, setShow24] = useState(false);
 const [show25, setShow25] = useState(false);
 const [show26, setShow26] = useState(false);
 const [show27, setShow27] = useState(false);
 const [show28, setShow28] = useState(false);
 const [show29, setShow29] = useState(false);

 const [show30, setShow30] = useState(false);
 const [show31, setShow31] = useState(false);
 const [show32, setShow32] = useState(false);
 const [show33, setShow33] = useState(false);
 const [show34, setShow34] = useState(false);

 const [show35, setShow35] = useState(false);
 const [show36, setShow36] = useState(false);
 const [show37, setShow37] = useState(false);
 const [show38, setShow38] = useState(false);
 const [show39, setShow39] = useState(false);
 const [show40, setShow40] = useState(false);
 const [show41, setShow41] = useState(false);
 const [show42, setShow42] = useState(false);
 const [show43, setShow43] = useState(false);
 const [show44, setShow44] = useState(false);
 const [show45, setShow45] = useState(false);
 const [show46, setShow46] = useState(false);
 const [show47, setShow47] = useState(false);
 const [show48, setShow48] = useState(false);
 const [show49, setShow49] = useState(false);
 const [show50, setShow50] = useState(false);
 const [show51, setShow51] = useState(false);
 const [show52, setShow52] = useState(false);
 const [show53, setShow53] = useState(false);
 const [show54, setShow54] = useState(false);
 const [show55, setShow55] = useState(false);
 const [show56, setShow56] = useState(false);
 const [show57, setShow57] = useState(false);
 const [show58, setShow58] = useState(false);
 const [show59, setShow59] = useState(false);
 const [show60, setShow60] = useState(false);
 const [show61, setShow61] = useState(false);
 const [show62, setShow62] = useState(false);
 const [show63, setShow63] = useState(false);
 const [show64, setShow64] = useState(false);
 const [show65, setShow65] = useState(false);
 const [show66, setShow66] = useState(false);
 const [show67, setShow67] = useState(false);
 const [show68, setShow68] = useState(false);
 const [show69, setShow69] = useState(false);
 const [show70, setShow70] = useState(false);
 const [show71, setShow71] = useState(false);
 const [show72, setShow72] = useState(false);
 const [show73, setShow73] = useState(false);
 const [show74, setShow74] = useState(false);
 const [show75, setShow75] = useState(false);
 const [show76, setShow76] = useState(false);
 const [show77, setShow77] = useState(false);
 const [show78, setShow78] = useState(false);
 const [show79, setShow79] = useState(false);
 const [show80, setShow80] = useState(false);

  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/online-vendor-registration.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>

        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Online Vendor Registration
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/online-vendor-registration"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Online Vendor Registration
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/contact-for-eprocurement"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Contact for e-Procurement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a href="https://iwebapps.noidapower.com:8032/powerProcAddr.aspx"
                        className="nav-link" target="_blank" rel="noreferrer"
                      >
                        Contact for Power Procurement
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/tenders"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Tenders
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content tab_main">

                  <div id="accordion">

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow80(!show80)}
                          >
                            <b>NIT No. NPCL/FY24-25/ MCB & MCCB /020 for “02 Years Rate Contract for the Supply of MCB & MCCB”</b>
                          </div>
                          {show80 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 11-12-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>02 Years Rate Contract for the Supply of MCB & MCCB</td>
                              <td> NIT No. NPCL/FY24-25/ MCB & MCCB /020 </td>
                              <td>                 
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLFY24-25_MCB_MCCB_020/NIT No. NPCL FY24-25 MCB & MCCB 020.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>
                            </table>     
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow79(!show79)}
                          >
                            <b>NIT No. NPCL/PRJ/FY24-25/16MVA PTR/019 for “AUGMENTATION OF POWER TRANSFORMER FROM 12.5MVA TO 16MVA AT 33/11KV SUBSTATIONS”</b>
                          </div>
                          {show79 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 03-12-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "AUGMENTATION OF POWER TRANSFORMER FROM 12.5MVA TO 16MVA AT 33/11KV SUBSTATIONS".</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/16MVA PTR/019 </td>
                            </tr>

                            </table>     
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLPRJFY24-2516MVAPTR019/Tech Spec_GTP_16 MVA & 20 MVA Power Transformer.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />                          
                              
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLPRJFY24-2516MVAPTR019/Bid Document.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />

                              </a>
                            </li>
                          </ul>

                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow78(!show78)}
                          >
                            <b>NIT No. NPCL/PRJ/FY24-25/CIVIL/018/Civil works at three locations of Greater Noida, a) Civil & Interior work to develop office facility at 33/11 kV Substation Building (ESS- 10) in Sector KP-5 b) Civil Work, PEB, EOT Crane & Fire Fighting Work of Kasna Store c) Civil work of Boundary walls for Pocket Substations</b>
                          </div>
                          {show78 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;&nbsp;&nbsp;  Date: 20-11-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Civil works at three locations of Greater Noida, a) Civil & Interior work to develop office facility at 33/11 kV Substation Building (ESS- 10) in Sector KP-5 b) Civil Work, PEB, EOT Crane & Fire Fighting Work of Kasna Store c) Civil work of Boundary walls for Pocket Substations.</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/CIVIL/018 </td>
                              <td>           
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLPRJFY24-25_CIVIL_018/Drawings.zip"
                                target="_blank" rel="noreferrer"
                              >
                               Drawings  
                              </a>
         
                                </td>
                                <td>
                  
                                <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLPRJFY24-25_CIVIL_018/Annexure-8 of Bid Document.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                            
                              </a>
         
                                </td>
                                <td>
                                <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLPRJFY24-25_CIVIL_018/Annexure-9 of Bid Document.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                            
                              </a>
                                </td>
                                <td>
                                <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLPRJFY24-25_CIVIL_018/Bid Document.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                              
                              </a>
                                </td>
                                <td>
                                <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLPRJFY24-25_CIVIL_018/Pre-Bid Queries.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                              
                              </a>
                                </td>
                            </tr>
                            </table>     
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow77(!show77)}
                          >
                            <b>Corrigendum-4 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 Dated 10.08.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida.”</b>
                          </div>
                          {show77 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 09-08-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-4 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 Dated 10.08.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida.”</td>
                              <td> NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLCIVESSFY24-25GRNOIDA016/CORRIGENDUM-4_Dated_15.11.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow76(!show76)}
                          >
                            <b>"Request for Selection (RfS) for Long Term Procurement of 300 MW Wind Solar Hybrid Power from Grid Connected Projects Under Tariff Based Competitive Bidding, RfS No: NPCL/LT/Hybrid/24-25 dated 08-November-2024"</b>
                          </div>
                          {show76 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3">RfS No. &nbsp;  Date: 08-11-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "Request for Selection (RfS) for Long Term Procurement of 300 MW Wind Solar Hybrid Power from Grid Connected Projects Under Tariff Based Competitive Bidding, RfS No: NPCL/LT/Hybrid/24-25 dated 08-November-2024".</td>
                              <td> RfS No: NPCL/LT/Hybrid/24-25 </td>
                            </tr>

                            </table>     
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLLTHybrid24-25/NPCL_PPA_Final.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />                          
                              Draft Power Purchase Document (PPA)
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLLTHybrid24-25/NPCL_RFS_Final.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                            Request for Selection (Rfs) Document
                              </a>
                            </li>
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLLTHybrid24-25/Addendum-1.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />                          
                              Addendum-1
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLLTHybrid24-25/Annexure-D.xlsx"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/excel-icon.png" alt="" />
                              Annexure-D
                              </a>
                            </li>
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLLTHybrid24-25/Addendum-3.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                              Amendment to RFS
                              </a>
                            </li>
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLLTHybrid24-25/Addendum-2.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                              Response to the Queries
                              </a>
                            </li>
                          </ul>

                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow75(!show75)}
                          >
                            <b>Corrigendum-3 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 Dated 10.08.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida.”</b>
                          </div>
                          {show75 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 09-08-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-3 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 Dated 10.08.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida.”</td>
                              <td> NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLCIVESSFY24-25GRNOIDA016/CORRIGENDUM-3_Dated_09.10.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow74(!show74)}
                          >
                            <b>Corrigendum-1 against NPCL/AUT/FY24-25/EOI/017 for “Expression of Interest from Intended Bidders for leasing Optical Fiber Cable.”</b>
                          </div>
                          {show74 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 12-09-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-1 dated 03.10.2024 against NIT No. NPCL/AUT/FY24-25/EOI/017  DT: 11.09.2024 For “EXPRESSION OF INTEREST FROM INTENDED BIDDERS FOR LEASING OPTICAL FIBER CABLE”. "Bid submission date is extended till 18th October’2024"</td>
                              <td> NIT No. NPCL/AUT/FY24-25/EOI/017 </td>
                              <td>                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/CORRIGENDUM1.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>      
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow73(!show73)}
                          >
                            <b>NPCL/AUT/FY24-25/EOI/017 for “Expression of Interest from Intended Bidders for leasing Optical Fiber Cable.”</b>
                          </div>
                          {show73 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 12-09-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>NPCL/AUT/FY24-25/EOI/017 for “Expression of Interest from Intended Bidders for leasing Optical Fiber Cable.”</td>
                              <td> NIT No. NPCL/AUT/FY24-25/EOI/017 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/EOI for leasing OFC.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow72(!show72)}
                          >
                            <b>Corrigendum-2 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 Dated 10.08.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida.”</b>
                          </div>
                          {show72 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 09-08-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-2 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 Dated 10.08.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida.”</td>
                              <td> NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLCIVESSFY24-25GRNOIDA016/CORRIGENDUM-2_Dated_28.08.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow71(!show71)}
                          >
                            <b>Corrigendum-1 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 Dated 10.08.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida.”</b>
                          </div>
                          {show71 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 09-08-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-1 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 Dated 10.08.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida.”</td>
                              <td> NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLCIVESSFY24-25GRNOIDA016/CORRIGENDUM-1_Dated_14.08.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow70(!show70)}
                          >
                            <b>NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida”</b>
                          </div>
                          {show70 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 09-08-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "Civil Construction of 5 Nos. of 33/11kV Electric Substation Building at various locations in Greater Noida".</td>
                              <td> NIT No. NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/016 </td>
                            </tr>

                            </table>     
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLCIVESSFY24-25GRNOIDA016/ANNEXURE-TYPICAL DRAWINGS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />                          
                              
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NPCLCIVESSFY24-25GRNOIDA016/Bid Document.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />

                              </a>
                            </li>
                          </ul>

                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow69(!show69)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-4: NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 dated 02.05.2024 for “PROCUREMENT OF 10 POSITION FULLY AUTOMATIC METER TESTING EQUIPMENT WITH REFERENCE METER OF CLASS 0.02”</b>
                          </div>
                          {show69 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 02.05.2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-4 against NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 dated 02.05.2024 for “PROCUREMENT OF 10 POSITION FULLY AUTOMATIC METER TESTING EQUIPMENT WITH REFERENCE METER OF CLASS 0.02”</td>
                              <td> NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NITNo_NPCLAUTFY24-25Meter_Test BenchCl002_Position10015/CORRIGENDUM-4_Dated_22.07.24.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow68(!show68)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-2: NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 dated 02.05.2024 for “PROCUREMENT OF 10 POSITION FULLY AUTOMATIC METER TESTING EQUIPMENT WITH REFERENCE METER OF CLASS 0.02”</b>
                          </div>
                          {show68 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 02.05.2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-2 against NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 dated 02.05.2024 for “PROCUREMENT OF 10 POSITION FULLY AUTOMATIC METER TESTING EQUIPMENT WITH REFERENCE METER OF CLASS 0.02”</td>
                              <td> NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NITNo_NPCLAUTFY24-25Meter_Test BenchCl002_Position10015/CORRIGENDUM-2_Dated_14.06.24.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow67(!show67)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-3: NIT No. NPCL/PRJ/FY24-25/EPC/013 DATED 18.04.2024 for “Supply of Balance of Plant and Erection, Testing & Commissioning of 33/11kV Substations and 33kV Switching Stations at Greater Noida”</b>
                          </div>
                          {show67 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 18.04.2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-3 against NIT No. NPCL/PRJ/FY24-25/EPC/013 DATED 18.04.2024 for “Supply of Balance of Plant and Erection, Testing & Commissioning of 33/11kV Substations and 33kV Switching Stations at Greater Noida”</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/EPC/013 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT_No-NPCLPRJFY24-25EPC-013/CORRIGENDUM-3_Dated_30.05.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow66(!show66)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-1: NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 dated 02.05.2024 for “PROCUREMENT OF 10 POSITION FULLY AUTOMATIC METER TESTING EQUIPMENT WITH REFERENCE METER OF CLASS 0.02”</b>
                          </div>
                          {show66 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 02.05.2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-1 against NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 dated 02.05.2024 for “PROCUREMENT OF 10 POSITION FULLY AUTOMATIC METER TESTING EQUIPMENT WITH REFERENCE METER OF CLASS 0.02”</td>
                              <td> NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NITNo_NPCLAUTFY24-25Meter_Test BenchCl002_Position10015/CORRIGENDUM-1_dt_22.05.24.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow65(!show65)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-2: NIT No. NPCL/PRJ/FY24-25/EPC/013 DATED 18.04.2024 for “Supply of Balance of Plant and Erection, Testing & Commissioning of 33/11kV Substations and 33kV Switching Stations at Greater Noida”</b>
                          </div>
                          {show65 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 18.04.2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-2 against NIT No. NPCL/PRJ/FY24-25/EPC/013 DATED 18.04.2024 for “Supply of Balance of Plant and Erection, Testing & Commissioning of 33/11kV Substations and 33kV Switching Stations at Greater Noida”</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/EPC/013 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT_No-NPCLPRJFY24-25EPC-013/CORRIGENDUM-2_Dated_20.05.2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow64(!show64)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-1: NIT No. NPCL/PRJ/FY24-25/EPC/013 DATED 18.04.2024 for “Supply of Balance of Plant and Erection, Testing & Commissioning of 33/11kV Substations and 33kV Switching Stations at Greater Noida”</b>
                          </div>
                          {show64 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 18.04.2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-1 against NIT No. NPCL/PRJ/FY24-25/EPC/013 DATED 18.04.2024 for “Supply of Balance of Plant and Erection, Testing & Commissioning of 33/11kV Substations and 33kV Switching Stations at Greater Noida”</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/EPC/013 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT_No-NPCLPRJFY24-25EPC-013/CORRIGENDUM-1_Dated_09.05.24.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow63(!show63)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-1: NIT No. NPCL/PRJ/FY24-25/33 & 11KV AIS/012 DATED 18.04.2024 for “SUPPLY OF 1250 AMPS 33kV AIS INDOOR SWITCHBOARD WITH TRANSFORMER PROTECTION & 1250 AMPS 11kV INDOOR AIR INSULATED SWITCHGEAR”</b>
                          </div>
                          {show63 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 18.04.2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-1 against NIT No. NPCL/PRJ/FY24-25/33 & 11KV AIS/012 DATED 18.04.2024 for “SUPPLY OF 1250 AMPS 33kV AIS INDOOR SWITCHBOARD WITH TRANSFORMER PROTECTION & 1250 AMPS 11kV INDOOR AIR INSULATED SWITCHGEAR”</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/33 & 11KV AIS/012 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-2533&11KVAIS-012/CORRIGENDUM-1_Dated_08.05.24_AIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow62(!show62)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-1: NIT No. NPCL/PRJ/FY24-25/GIS/011 DATED 18.04.2024 for “SUPPLY AND ETC OF 33KV DOUBLE BUS INDOOR GAS INSULATED SWITCHGEAR (9 PANEL BOARDS)”</b>
                          </div>
                          {show62 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 03-05-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-1 against NIT No. NPCL/PRJ/FY24-25/GIS/011 DATED 18.04.2024 for “SUPPLY AND ETC OF 33KV DOUBLE BUS INDOOR GAS INSULATED SWITCHGEAR (9 PANEL BOARDS)”</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/GIS/011 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-25GIS-011/CORRIGENDUM-1_Dated_08.05.24_GIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow61(!show61)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-1: NIT No. NPCL/PRJ/FY24-25/PTR/010 Dated 18.04.2024 for “SUPPLY OF 33/11 kV 12.5 MVA Power Transformers”</b>
                          </div>
                          {show61 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 18.04.2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-1 against NIT No. NPCL/PRJ/FY24-25/PTR/010 Dated 18.04.2024 for “SUPPLY OF 33/11 kV 12.5 MVA Power Transformers”</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/PTR/010 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-25PTR-010/CORRIGENDUM_1_Dated_08.05.24_PTR.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow60(!show60)}
                          >
                            <b>NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 for “PROCUREMENT OF 10 POSITION FULLY AUTOMATIC METER TESTING EQUIPMENT WITH REFERENCE METER OF CLASS 0.02”</b>
                          </div>
                          {show60 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 03-05-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "PROCUREMENT OF 10 POSITION FULLY AUTOMATIC METER TESTING EQUIPMENT WITH REFERENCE METER OF CLASS 0.02".</td>
                              <td> NIT No. NPCL/AUT/FY24-25/Meter Test Bench Cl:0.02, Position:10 /015 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NITNo_NPCLAUTFY24-25Meter_Test BenchCl002_Position10015/RFP for Meter_Test_Bench.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow59(!show59)}
                          >
                            <b>NIT No. NPCL/AUT/FY24-25/TESTING OF HT METERING SYSTEM/014 for “TESTING OF HT METERING SYSTEM”</b>
                          </div>
                          {show59 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 19-04-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "TESTING OF HT METERING SYSTEM".</td>
                              <td> NIT No. NPCL/AUT/FY24-25/TESTING OF HT METERING SYSTEM/014 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLAUTFY24-25TESTINGOFHTMETERINGSYSTEM-014/Testing of HT connection-RFP-F.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow58(!show58)}
                          >
                            <b> NIT No. NPCL/PRJ/FY24-25/EPC/013 for “Supply of Balance of Plant and Erection, Testing & Commissioning of 33/11kV Substations and 33kV Switching Stations at Greater Noida” </b>
                          </div>
                          {show58 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 18-04-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "Supply of Balance of Plant and Erection, Testing & Commissioning of 33/11kV Substations and 33kV Switching Stations at Greater Noida".</td>
                              <td>NIT No. NPCL/PRJ/FY24-25/EPC/013</td>
                              <td>
            
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT_No-NPCLPRJFY24-25EPC-013/Bid Document_Balance of Plant.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                                <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT_No-NPCLPRJFY24-25EPC-013/Technical Specifications.zip"
                                target="_blank" rel="noreferrer"
                              >
                               Technical Specifications  
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow57(!show57)}
                          >
                            <b>NIT No. NPCL/PRJ/FY24-25/33 & 11KV AIS/012 for "SUPPLY OF  1250 AMPS 33kV AIS INDOOR SWITCHBOARD WITH TRANSFORMER PROTECTION & 1250 AMPS 11kV INDOOR AIR INSULATED SWITCHGEAR" </b>
                          </div>
                          {show57 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No. &nbsp;  Date: 18-04-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for "SUPPLY OF  1250 AMPS 33kV AIS INDOOR SWITCHBOARD WITH TRANSFORMER PROTECTION & 1250 AMPS 11kV INDOOR AIR INSULATED SWITCHGEAR".</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/33 & 11KV AIS/012 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-2533&11KVAIS-012/Bid Document_AIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />                          
                              
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-2533&11KVAIS-012/TS_GTP_33KV_AIS_CONTROLLED_REV_A.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />

                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-2533&11KVAIS-012/TS_GTP_11KV_AIS_CONTROLLED_REV_B.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />

                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow56(!show56)}
                          >
                            <b>NIT No. NPCL/PRJ/FY24-25/GIS/011 for "SUPPLY AND ETC OF 33KV DOUBLE BUS INDOOR GAS INSULATED SWITCHGEAR (9 PANEL BOARDS)" </b>
                          </div>
                          {show56 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No. &nbsp;  Date: 18-04-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for "SUPPLY AND ETC OF 33KV DOUBLE BUS INDOOR GAS INSULATED SWITCHGEAR (9 PANEL BOARDS)".</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/GIS/011 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-25GIS-011/Bid Document_GIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />                          
                              
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-25GIS-011/TS_GTP_33KV_DOUBLE_BUS_GIS_CONTROLLED_R-B.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />

                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow55(!show55)}
                          >
                            <b>NIT No. NPCL/PRJ/FY24-25/PTR/010 for "SUPPLY OF 33/11 kV 12.5 MVA Power Transformers" </b>
                          </div>
                          {show55 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No. &nbsp;  Date: 18-04-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for "SUPPLY OF 33/11 kV 12.5 MVA Power Transformers".</td>
                              <td> NIT No. NPCL/PRJ/FY24-25/PTR/010 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-25PTR-010/Bid Document_PTR.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />                          
                              
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLPRJFY24-25PTR-010/ANNEXURE-1 TS_12.5MVA_33_11kV_ONAN_Three_Phase_Power_Transformer.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />

                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow54(!show54)}
                          >
                            <b>NOTIFICATION for CORRIGENDUM-1: NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/009 </b>
                          </div>
                          {show54 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Civil Tender Description</th>
                              <th className="col-sm-3">NIT No. &nbsp;  Date: 29-03-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/009 Dated 29.03.2024 for “Civil Construction of 5 Nos. of 33/11kV Electric Substation cum 33kV Switching Station and 33kV Switching Station Building at various locations in Greater Noida”.</td>
                              <td> NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/009 </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY24-25GRNOIDA-009/CORRIGENDUM-1.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>                                            
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow53(!show53)}
                          >
                            <b>NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/009 for "Civil Construction of 5 Nos. of 33/11kV Electric Substation cum 33kV Switching Station and 33kV Switching Station Building at various locations in Greater Noida" </b>
                          </div>
                          {show53 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No. &nbsp;  Date: 29-03-2024</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for "Civil Construction of 5 Nos. of 33/11kV Electric Substation cum 33kV Switching Station and 33kV Switching Station Building at various locations in Greater Noida".</td>
                              <td> NIT No. NPCL/CIV/ESS/FY24-25/GR. NOIDA/009 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY24-25GRNOIDA-009/Bid Document for GR NOIDA009.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                           
                              
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY24-25GRNOIDA-009/ANNEXURE-TYPICAL DRAWINGS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                            
                               {/* ANNEXURE-TYPICAL DRAWINGS */}
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow51(!show51)}
                          >
                            <b> NOTIFICATION for Corrigendum-1 :(NIT No. NPCL/EPC/ESS/FY23-24/GR. NOIDA/007)  </b>
                      </div>
                          {show51 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3"> NIT No. </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No. NPCL/EPC/ESS/FY23-24/GR. NOIDA/007 dated 28.09.2023 for " Supply, Installation, Testing and Commissioning including all necessary Civil Works for 33/11 kV Substation cum 33kV Switching Stations and 33kV Switching Stations in various sectors of Greater Noida City" .
 </td>
                              <td>
                                    NIT No. NPCL/EPC/ESS/FY23-24/GR. NOIDA/007

                              </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-10/CORRIGENDUM-1%20dt%2012.10.23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                                <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-10/Proposed Substation Layout & SLD.rar"
                                target="_blank" rel="noreferrer"
                              >
                               Substation Proposed Layouts & SLDs  
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow50(!show50)}
                          >
                            <b> NIT No. NPCL/EPC/ESS/FY23-24/GR. NOIDA/007 for “Supply, Installation, Testing and Commissioning including all necessary Civil Works for 33/11 kV Substation cum 33kV Switching Stations and 33kV Switching Stations in various sectors of Greater Noida City” </b>
                          </div>
                          {show50 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3"> NIT No. </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> TENDER NOTIFICATION For “Supply, Installation, Testing and Commissioning including all necessary Civil Works for 33/11 kV Substation cum 33kV Switching Stations and 33kV Switching Stations in various sectors of Greater Noida City ".
 </td>
                              <td>
                                    NIT No. NPCL/EPC/ESS/FY23-24/GR. NOIDA/007 Dated 28.09.2023

                              </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/Bid Document for Turnkey EPC Project 28-09-2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                                <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/SECTION-XI.rar"
                                target="_blank" rel="noreferrer"
                              >
                               SECTION-XI  
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow49(!show49)}
                          >
                            <b> Notification for Corrigendum 2: (NIT No: Notification for Corrigendum 2: (NIT No: NPCL/AUT/FY23-24/P2P ET/006)) </b>
                          </div>
                          {show49 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3"> NPCL/AUT/FY23-24/P2P ET/006 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-2 against NIT No: NPCL/AUT/FY23-24/P2P ET/006 DT: 16.08.2023 For " Expression of Interest(EOI)-For P2P Energy Trading using Blockchain”, technical queries & addendum document is attached for reference. </td>
                              <td>
                              NPCL/AUT/FY23-24/P2P ET/006
                              </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/Corrigendum 2_NIT No_NPCL_AUT_FY23-24_P2P ET_006.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                                <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/Technical Queries for NIT No. NPCL_AUT_FY23-24_P2P_ ET006.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                        <div className="card-header"
                              onClick={() => setShow48(!show48)}
                          >
                            <b> NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-01 </b>
                          </div>
                          {show48 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                         
                              <th className="col-sm-11">Tender Description</th>
                              
                            </tr>
                            <tr>
                              <td> Following is the Technically Qualified bidder for NIT No.: NPCL/CIV/ESS/FY23-24/SECTOR-01 dated: 06.07.2023 for " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-01, Greater Noida " </td>
                              </tr>
                              <tr>
                                <td>
                                 <strong> 1. M/s Sanmul Construction P. Ltd.   </strong> 
                                </td>
                            </tr>
                            </table>
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow47(!show47)}
                          >
                            <b> NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-10 </b>
                          </div>
                          {show47 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                         
                              <th className="col-sm-11">Tender Description</th>
                              
                            </tr>
                            <tr>
                       
                              <td> Following are the Technically Qualified bidders for NIT No.: NPCL/CIV/ESS/FY23-24/SECTOR-10 dated: 06.07.2023 for " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard at Sector-10, Greater Noida " </td>
          
                              
                              </tr>

                              <tr>
        
                                <td>
                  
                                <strong> 1. M/s Intercon Buildtech P. Ltd.  </strong> 
                                 <strong> 2. M/s Sanmul Construction P. Ltd.   </strong> 
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow46(!show46)}
                          >
                            <b> NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-10 </b>
                          </div>
                          {show46 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                         
                              <th className="col-sm-11">Tender Description</th>
                              
                            </tr>
                            <tr>
                       
                              <td> Following are the Technically Qualified bidders for NIT No.: NPCL/CIV/ESS/FY23-24/ECOTECH-10 dated: 06.07.2023 for " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-10, Greater Noida " </td>
          
                              
                              </tr>

                              <tr>
        
                                <td>
                  
                                <strong> 1. M/s Intercon Buildtech P. Ltd.  </strong> 
                                 <strong> 2. M/s Sanmul Construction P. Ltd.   </strong> 
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow45(!show45)}
                          >
                            <b> NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-04 </b>
                          </div>
                          {show45 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                           
                              <th className="col-sm-8">Tender Description</th>
 
                            </tr>
                            <tr>
                           
                              <td> Following are the Technically Qualified bidders for NIT No.: NPCL/CIV/ESS/FY23-24/SECTOR-04 dated: 06.07.2023 for " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-04, Greater Noida " </td>
 
 
                              </tr>

                                          <tr>

                                            <td>

                                            <strong> 1. M/s Intercon Buildtech P. Ltd.  </strong> 
                                            <strong> 2. M/s Sanmul Construction P. Ltd.   </strong> 

                                            </td>
                                          </tr>
                      

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow44(!show44)}
                          >
                            <b> Notification for Corrigendum 1: (NIT No: NPCL/AUT/FY23-24/P2P ET/006) </b>
                          </div>
                          {show44 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3"> NPCL/AUT/FY23-24/P2P ET/006 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No: NPCL/AUT/FY23-24/P2P ET/006 DT: 16.08.2023 For " Expression of Interest(EOI)-For P2P Energy Trading using Blockchain." <b>"Bid submission date is extended till 19th-October-2023."</b></td>
                              <td>
                              NPCL/AUT/FY23-24/P2P ET/006
                              </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/NIT for P2P.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                                <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/Corrigendum Document.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow43(!show43)}
                          >
                            <b> Notification for Corrigendum 3: (NPCL/PRJ/FY23-24/11KVAIS/004) </b>
                          </div>
                          {show43 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3"> NPCL/PRJ/FY23-24/11KVAIS/004 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-3 dated 23.08.2023 against NIT No. NPCL/PRJ/FY23-24/11KVAIS/004 DT: 10.07.2023 For ‘’ SUPPLY OF 1250 AMPS 11kV INDOOR AIR INSULATED SWITCHGEAR." "Bid submission date is extended till 28th-August-2023."</td>
                              <td>
                              NPCL/PRJ/FY23-24/11KVAIS/004
                              </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/EXTENSION IN BID SUBMISSION DATE NPCL PRJ FY 23_24 11 kV AIS 004.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow42(!show42)}
                          >
                            <b> Notification for Corrigendum 3 : (NPCL/PRJ/FY23-24/33KVAIS/003) </b>
                          </div>
                          {show42 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3"> NPCL/PRJ/FY23-24/33KVAIS/003</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-3 dated 23.08.2023 against NIT No. Tender Enquiry No.: NPCL/PRJ/FY23-24/33KVAIS/003 for 1250 AMPS 33kV AIS INDOOR SWITCHBOARD WITH TRANSFORMER PROTECTION " "Bid submission date is extended till 28th-August-2023."</td>
                              <td>
                              NPCL/PRJ/FY23-24/33KVAIS/003
                              </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/EXTENSION IN BID SUBMISSION DATE NPCL PRJ FY 23_24 33 kV AIS 003.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow41(!show41)}
                          >
                            <b> Notification for Corrigendum 3: (NIT no. NPCL/PRJ/FY23-24/GIS/002) </b>
                          </div>
                          {show41 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3"> NIT no. NPCL/PRJ/FY23-24/GIS/002 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-3 dated 23.08.2023 against NIT No.: NPCL/PRJ/FY23-24/GIS/002 for 33KV DOUBLE BUS INDOOR GAS INSULATED SWITCHGEAR (9 PANEL BOARDS) "Bid submission date is extended till 28th-August-2023."</td>
                              <td>
                              NIT no. NPCL/PRJ/FY23-24/GIS/002
                              </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/EXTENSION IN BID SUBMISSION DATE NPCL PRJ FY23_ 24 GIS 002.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow40(!show40)}
                          >
                            <b> Tender Notification for "Expression of Interest(EOI)-For P2P Energy Trading using Blockchain" </b>
                          </div>
                          {show40 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-8">Tender Description</th>
                              <th className="col-sm-3"> NIT No. Dated 16.08.2023 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for “Expression of Interest(EOI)-For P2P Energy Trading using Blockchain.</td>
                              <td>
                              NPCL/AUT/FY23-24/P2P ET/006
                              </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/EOI.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow39(!show39)}
                          >
                            <b>Notification for Corrigendum 2: (NIT No: NPCL/PRJ/FY23-24/CAB/005)</b>
                          </div>
                          {show39 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-2 against NIT No: NPCL/PRJ/FY23-24/CAB/005 DT: 10.07.2023 For " SUPPLY OF 33kV 3C X 400 SQ. MM ALUMINIUM XLPE GRADE CABLE & 11kV 3C X 300 SQ. MM ALUMINIUM XLPE GRADE CABLE." "Bid submission date is extended till 23rd-August-2023."</td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/NIT for Cable.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow38(!show38)}
                          >
                            <b>Notification for Corrigendum 2: (NIT no. NPCL/PRJ/FY23-24/GIS/002)</b>
                          </div>
                          {show38 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-2 against NIT No: NPCL/PRJ/FY23-24/GIS/002 DT: 10.07.2023 For " SUPPLY & ETC OF 33KV DOUBLE BUS INDOOR GAS INSULATED SWITCHGEAR (9 PANEL BOARDS." "Bid submission date is extended till 23rd-August-2023."</td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/NIT for 33KV GIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow37(!show37)}
                          >
                            <b> Notification for Corrigendum 2 (NIT No. NPCL/PRJ/FY23-24/PTR/001) </b>
                          </div>
                          {show37 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-2 against NIT No: NPCL/PRJ/FY23-24/PTR/001 DT: 10.07.2023 For " SUPPLY OF 33/11 kV 12.5 MVA Power Transformers." "Bid submission date is extended till 23rd-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/NIT for PTR.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div> 

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow36(!show36)}
                          >
                            <b> Notification for Corrigendum 2: (NPCL/PRJ/FY23-24/33KVAIS/003) </b>
                          </div>
                          {show36 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-2 against NIT No: NPCL/PRJ/FY23-24/33KVAIS/003 DT: 10.07.2023 For " SUPPLY OF 1250 AMPS 33kV AIS INDOOR SWITCHBOARD WITH TRANSFORMER PROTECTION." "Bid submission date is extended till 23rd-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/NIT for 33KV AIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow35(!show35)}
                          >
                            <b>Notification for Corrigendum 2: (NPCL/PRJ/FY23-24/11KVAIS/004)</b>
                          </div>
                          {show35 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-2 against NIT No: NPCL/PRJ/FY23-24/11KVAIS/004 DT: 10.07.2023 For " SUPPLY OF 1250 AMPS 11kV INDOOR AIR INSULATED SWITCHGEAR." "Bid submission date is extended till 23rd-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/12-08/NIT for 11KV AISY.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div> 

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow34(!show34)}
                          >
                            <b> Notification for Corrigendum2: (: NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-01)</b>
                          </div>
                          {show34 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-2 against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-01 DT: 06.07.2023 For "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-01, Greater Noida." "Bid submission date is extended till 22nd-August-2023."</td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/06-07/NIT for SECTOR 01.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow33(!show33)}
                          >
                            <b> Notification for Corrigendum2: (NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-08)</b>
                          </div>
                          {show33 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-2 against NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-08 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-08, Greater Noida, Greater Noida." "Bid submission date is extended till 22nd-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/06-07/NIT for ECOTECH 08.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div> 

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow32(!show32)}
                          >
                            <b> Notification for Corrigendum2: (NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-10) </b>
                          </div>
                          {show32 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-2 against NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-10 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-10, Greater Noida." "Bid submission date is extended till 22nd-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/06-07/NIT for ECOTECH 10.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow31(!show31)}
                          >
                            <b>Notification for Corrigendum2: (NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-04)</b>
                          </div>
                          {show31 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-2 against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-04 DT: 06.07.2023 For "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-04, Greater Noida." "Bid submission date is extended till 22nd-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/06-07/NIT for SECTOR 04.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div> 

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow30(!show30)}
                          >
                            <b> Notification for Corrigendum2 (NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-10) </b>
                          </div>
                          {show30 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-2 against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-10 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard at Sector-10, Greater Noida." "Bid submission date is extended till 22nd-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/06-07/NIT for SECTOR 10.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow7(!show7)}
                          >
                            <b> Notification for Corrigendum: (NPCL/PRJ/FY23-24/33KVAIS/003)</b>
                          </div>
                          {show7 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No. Tender Enquiry No.: NPCL/PRJ/FY23-24/33KVAIS/003 for 1250 AMPS 33kV AIS INDOOR SWITCHBOARD WITH TRANSFORMER PROTECTION " "Bid submission date is extended till 11th-August-2023."</td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/CORRIGENDUM -1 DATED– EXTENSION IN BID SUBMISSION DATE for NIT NPCL PRJ FY23 24 33KV AIS 003.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow29(!show29)}
                          >
                            <b> Notification for Corrigendum: (NPCL/PRJ/FY23-24/11KVAIS/004)</b>
                          </div>
                          {show29 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Corrigendum-1 against NIT No. NPCL/PRJ/FY23-24/11KVAIS/004 DT: 10.07.2023 For ‘’ SUPPLY OF 1250 AMPS 11kV INDOOR AIR INSULATED SWITCHGEAR." "Bid submission date is extended till 11th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/CORRIGENDUM -1 DATED - EXTENSION IN BID SUBMISSION DATE NPCL PRJ FY23-24 11KV AIS 004.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div> 

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow28(!show28)}
                          >
                            <b> Pre Bid Queries against NIT No. NPCL/PRJ/FY23-24/PTR/001 </b>
                          </div>
                          {show28 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Pre Bid Queries against NIT No. NPCL/PRJ/FY23-24/PTR/001 dated 10-07-2023 For " SUPPLY OF 33/11 kV 12.5 MVA Power Transformers."  </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Pre Bid Queries Power Tfr.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow6(!show6)}
                          >
                            <b>Notification for Corrigendum: (NIT no. NPCL/PRJ/FY23-24/GIS/002)</b>
                          </div>
                          {show6 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No.: NPCL/PRJ/FY23-24/GIS/002 for 33KV DOUBLE BUS INDOOR GAS INSULATED SWITCHGEAR (9 PANEL BOARDS) "Bid submission date is extended till 11th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/CORRIGENDUM -1 DATED – EXTENSION IN BID SUBMISSION DATE NIT NPCL PRJ FY 23-24 GIS 002.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div> 

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow27(!show27)}
                          >
                            <b> Notification for Corrigendum (NIT No: NPCL/PRJ/FY23-24/CAB/005) </b>
                          </div>
                          {show27 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No: NPCL/PRJ/FY23-24/CAB/005 DT: 10.07.2023 For " SUPPLY OF 33kV 3C X 400 SQ. MM ALUMINIUM XLPE GRADE CABLE & 11kV 3C X 300 SQ. MM ALUMINIUM XLPE GRADE CABLE." "Bid submission date is extended till 16th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/NIT for Cable.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow26(!show26)}
                          >
                            <b> Notification for Corrigendum (NIT No. NPCL/PRJ/FY23-24/PTR/001) </b>
                          </div>
                          {show26 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No: NPCL/PRJ/FY23-24/PTR/001 DT: 10.07.2023 For " SUPPLY OF 33/11 kV 12.5 MVA Power Transformers." "Bid submission date is extended till 16th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/NIT for PTR.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow25(!show25)}
                          >
                            <b>Notification for Corrigendum: (NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-01) </b>
                          </div>
                          {show25 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-01 DT: 06.07.2023 For "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-01, Greater Noida." "Bid submission date is extended till 9th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Sector-01.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow24(!show24)}
                          >
                            <b> Notification for Corrigendum: (NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-08) </b>
                          </div>
                          {show24 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-08 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-08, Greater Noida, Greater Noida." "Bid submission date is extended till 9th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Ecotech-08.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow23(!show23)}
                          >
                            <b> Notification for Corrigendum: (NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-10) </b>
                          </div>
                          {show23 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-10 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-10, Greater Noida." "Bid submission date is extended till 9th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Ecotech-10.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow22(!show22)}
                          >
                            <b> Notification for Corrigendum: (NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-04) </b>
                          </div>
                          {show22 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-04 DT: 06.07.2023 For "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-04, Greater Noida." "Bid submission date is extended till 9th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Sector-04.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow21(!show21)}
                          >
                            <b> Notification for Corrigendum: (NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-10) </b>
                          </div>
                          {show21 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Corrigendum-1 against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-10 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard at Sector-10, Greater Noida." "Bid submission date is extended till 9th-August-2023." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Sector-10.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow20(!show20)}
                          >
                            <b> Pre Bid Queries for NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-08 </b>
                          </div>
                          {show20 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Pre Bid Queries against NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-08 DT: 06.07.2023 For "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-08, Greater Noida, Greater Noida."  </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Pre Bid Queries Ecotech 08.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
                                </td>
                            </tr>
                            </table>
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow19(!show19)}
                          >
                            <b> Pre Bid Queries for NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-10 </b>
                          </div>
                          {show19 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Pre Bid Queries against NIT No: NPCL/CIV/ESS/FY23-24/ECOTECH-10 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-10, Greater Noida."  </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Pre Bid Queries Ecotech 10.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow18(!show18)}
                          >
                            <b> Pre Bid Queries for NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-04 </b>
                          </div>
                          {show18 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Pre Bid Queries against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-04 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-04, Greater Noida."  </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Pre Bid Queries Sector 04.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow17(!show17)}
                          >
                            <b> Pre Bid Queries for NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-01 </b>
                          </div>
                          {show17 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Pre Bid Queries against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-01 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-01, Greater Noida." </td>
                              <td>
                  
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Pre Bid Queries Sector 01.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
         
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow16(!show16)}
                          >
                            <b> Pre Bid Queries for NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-10  </b>
                          </div>
                          {show16 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Pre Bid Queries against NIT No: NPCL/CIV/ESS/FY23-24/SECTOR-10 DT: 06.07.2023 For " Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard at Sector-10, Greater Noida."  </td>
                              <td>
           
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Pre Bid Queries Sector 10.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
 
                                </td>
                            </tr>

                            </table>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow15(!show15)}
                          >
                            <b> Pre Bid Queries for NIT No. NPCL/PRJ/FY23-24/CAB/005 </b>
                          </div>
                          {show15 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-10">Tender Description</th>
                              <th className="col-sm-1">  </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Pre Bid Queries against NIT No: NPCL/PRJ/FY23-24/CAB/005 DT: 06.07.2023 For "SUPPLY OF 33kV 3C X 400 SQ. MM ALUMINIUM XLPE GRADE CABLE & 11kV 3C X 300 SQ. MM ALUMINIUM XLPE GRADE CABLE." </td>
                              <td>
                               
                      
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/26-07/Pre Bid Queries for Cables.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
                
                                </td>
                            </tr>

                            </table>

                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow14(!show14)}
                          >
                            <b> Tender Notification for Supply 33kV 3C X 400 Sq. mm. Aluminium XLPE Grade Cable & 11kV 3C X 300 Sq. mm. Aluminium XLPE Grade Cable: NIT No. NPCL/PRJ/FY23-24/CAB/005 </b>
                          </div>
                          {show14 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Tender Description</th>
                              <th className="col-sm-4">NIT No. &nbsp;  Date: 10-07-2023 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for “SUPPLY OF 33kV 3C X 400 SQ. MM ALUMINIUM XLPE GRADE CABLE & 11kV 3C X 300 SQ. MM ALUMINIUM XLPE GRADE CABLE".</td>
                              <td>

                                NPCL/PRJ/FY23-24/CAB/005 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/Bid Document Supply of 33kV  11kV XLPE Cable.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                          
                               
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/TS_GTP_DWG_11kV_300sqmm_XLPE_Cable_Rev_A.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               TS GTP DWG 11kV 300sqmm XLPE Cable Rev A
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/TS_GTP_DWG_33kV_400sqmm_XLPE_Cable_Rev_A.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               TS GTP DWG 33kV 400sqmm XLPE Cable Rev A
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow13(!show13)}
                          >
                            <b> Tender Notification for Supply of 1250 Amps 11kV Indoor Air Insulated Switchgear (AIS): NIT No. NPCL/PRJ/FY23-24/11KVAIS/004. </b>
                          </div>
                          {show13 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Tender Description</th>
                              <th className="col-sm-4">NIT No. &nbsp;  Date: 10-07-2023 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for “ SUPPLY OF 1250 AMPS 11kV INDOOR AIR INSULATED SWITCHGEAR ".</td>
                              <td>
                               
                                
                              NPCL/PRJ/FY23-24/11KVAIS/004</td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/Bid Document Supply of 11kV ID AIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               
                              <img src="assets/img/pdf.png" alt="" />
                         
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/TS_GTP_11kV_ID_VCB_BOARD1250A_CONTROLLED.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               TS GTP 11kV ID VCB BOARD1250A CONTROLLED
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow12(!show12)}
                          >
                            <b> Tender Notification for Supply of 1250 Amps 33kV AIS Indoor Switchboard: NIT No. NPCL/PRJ/FY23-24/33KVAIS/003 </b>
                          </div>
                          {show12 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Tender Description</th>
                              <th className="col-sm-4">NIT No.  &nbsp;  Date: 10-07-2023</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for “ SUPPLY OF 1250 AMPS 33kV AIS INDOOR SWITCHBOARD WITH TRANSFORMER PROTECTION  ".</td>
                              <td>NPCL/PRJ/FY23-24/33KVAIS/003</td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/Bid Document Supply of 33kV ID AIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              Bid Document Supply of 33kV ID AIS
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/TS_GTP_33KV_AIS_ID_BOARD_CONTROLLED.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               TS GTP 33KV AIS ID BOARD CONTROLLED
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow11(!show11)}
                          >
                            <b> Tender Notification for Supply & ETC of 1600 Amps 33KV Double Bus Indoor Gas Insulated Switchgear (GIS): NIT No. NPCL/PRJ/FY23-24/GIS/002. </b>
                          </div>
                          {show11 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Tender Description</th>
                              <th className="col-sm-4">NIT No. &nbsp;  Date: 10-07-2023 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for “ SUPPLY & ETC OF 33KV DOUBLE BUS INDOOR GAS INSULATED SWITCHGEAR (9 PANEL BOARDS) ".</td>
                              <td>
                               
                                
                              NPCL/PRJ/FY23-24/GIS/002 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/Bid Document Supply & ETC of 33kV DB ID GIS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                Bid Document Supply & ETC of 33kV DB ID GIS
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/TS_GTP_33KV_DOUBLE_BUS_GIS_CONTROLLED_R-A.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               TS GTP 33KV DOUBLE BUS GIS CONTROLLED R-A
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow10(!show10)}
                          >
                            <b> Tender Notification for Supply of 33/11 kV 12.5 MVA Power Transformers: NIT No. NPCL/PRJ/FY23-24/PTR/001 </b>
                          </div>
                          {show10 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7"> Tender Description</th>
                              <th className="col-sm-4">NIT No.  &nbsp;  Date: 10-07-2023</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for “ SUPPLY OF 33/11 kV 12.5 MVA Power Transformers </td>
                              <td>NPCL/PRJ/FY23-24/PTR/001</td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/Bid Document 12.5 MVA Power Transformer.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                Bid Document 12.5 MVA Power Transformer
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/10-07/TS_12.5MVA_33_11kV_ONAN_Three_Phase_Power_Transformer_Controlled_Copy.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               TS 12.5MVA 33 11kV ONAN Three Phase Power Transformer Controlled
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                  <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow1(!show1)}
                          >
                            <b> Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-08, Greater Noida".  </b>
                          </div>
                          {show1 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No. &nbsp;  Date: 06-07-2023 </th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-08, Greater Noida".</td>
                              <td>
                               
                              NPCL/CIV/ESS/FY23-24/ECOTECH-08</td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24ECOTECH-08/Bid Document of Civil Works- Ecotech-08.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                           
                                 
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24ECOTECH-08/ANNEXURE-TYPICAL DRAWINGS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               ANNEXURE-TYPICAL DRAWINGS
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow2(!show2)}
                          >
                            <b> Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-10, Greater Noida </b>
                          </div>
                          {show2 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No.  &nbsp;  Date: 06-07-2023</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector Ecotech-10, Greater Noida".</td>
                              <td>NPCL/CIV/ESS/FY23-24/ECOTECH-10</td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24ECOTECH-10/Bid Document of Civil Works- Ecotech-10.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                Bid Document of Civil Works- Ecotech-10
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24ECOTECH-10/ANNEXURE-TYPICAL DRAWINGS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               ANNEXURE-TYPICAL DRAWINGS
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>
                 
                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow9(!show9)}
                          >
                            <b>  Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-01, Greater Noida </b>
                          </div>
                          {show9 ? (
                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No.  &nbsp;  Date: 06-07-2023</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-01, Greater Noida".</td>
                              <td> NPCL/CIV/ESS/FY23-24/SECTOR-01 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24SECTOR-01/Bid Document of Civil Works- Sector-01.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                Bid Document of Civil Works- Sector-01
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24SECTOR-01/ANNEXURE-TYPICAL DRAWINGS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               ANNEXURE-TYPICAL DRAWINGS
                              </a>
                            </li>
                          </ul>
                            
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow8(!show8)}
                          >
                            <b> Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-04, Greater Noida </b>
                          </div>
                          {show8 ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No.  &nbsp;  Date: 06-07-2023</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td>Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard & Boundary Wall at Sector-04, Greater Noida".</td>
                              <td> NPCL/CIV/ESS/FY23-24/SECTOR-04 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24SECTOR-04/Bid Document of Civil Works- Sector-04.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                NPCL/CIV/ESS/FY23-24/SECTOR-04
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24SECTOR-04/ANNEXURE-TYPICAL DRAWINGS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               ANNEXURE-TYPICAL DRAWINGS
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>                  

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow(!show)}
                          >
                            <b> Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard at Sector-10, Greater Noida </b>
                          </div>
                          {show ? (

                            <div className="card-body">
                            <table className="table table-responsive table-striped">
                            <tr>
                              <th className="col-sm-1">Sr. No.</th>
                              <th className="col-sm-7">Civil Tender Description</th>
                              <th className="col-sm-4">NIT No.  &nbsp;  Date: 06-07-2023</th>
                            </tr>
                            <tr>
                              <td>1.</td>
                              <td> Notice Inviting Tenders for "Civil Construction of 33/11kV Electric Substation cum 33kV Switching Station Building with Outdoor Transformer Yard at Sector-10, Greater Noida".</td>
                              <td> NPCL/CIV/ESS/FY23-24/SECTOR-10 </td>
                            </tr>

                            </table>
                            <ul className="tariff_standard">
                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24SECTOR-10/Bid Document of Civil Works- Sector-10.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                           
                              
                              </a>
                            </li>

                            <li>
                            <a
                                href="https://iwebapps.noidapower.com:8032/download/NIT/NIT-No-NPCLCIVESSFY23-24SECTOR-10/ANNEXURE-TYPICAL DRAWINGS.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              <img src="assets/img/pdf.png" alt="" />
                            
                               {/* ANNEXURE-TYPICAL DRAWINGS */}
                              </a>
                            </li>
                          </ul>
                              
                            </div>
                            ) : null}
                   </div>

                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow5(!show5)}
                          >
                            <b> Tender for banking of power for 01.11.2022 to 30.09.2023
                        on Short Term Basis</b>
                          </div>
                          {show5 ? (

                            <div className="card-body">
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/Banking_tender_for_01.11.2022_to_30.09.2023.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                Tender
                              </a>
                            </li>
                          </ul>
                            </div>
                            ) : null}
                   </div>
                   
                {/*    
                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow4(!show4)}
                          >
                            <b> Inviting Bids for Pilot of “P2P Energy trading using
                        Blockchain”. Submission of Technical queries - Date
                        extended to 24/01/2022</b>
                          </div>
                          {show4 ? (

                            <div className="card-body">
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/RFP_P2P_Energy_Trading_Using_Blockchin_Pilot_at_NPCL.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                RfP
                              </a>
                            </li>
                          </ul>
                            </div>
                            ) : null}
                   </div>


                   
                   <div className="card">
                          <div
                            className="card-header"
                            onClick={() => setShow3(!show3)}
                          >
                            <b>  Inviting Bids for Pilot of “P2P Energy trading using
                        Blockchain”-Pre-bid Query Response and Addendum</b>
                          </div>
                          {show3 ? (

                            <div className="card-body">
                            <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/P2P_Energy_Trading_using_Blockchain_Pilot_Pre-bid_Queries_Response.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                Response
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/P2P_Energy_Trading_using_Blockchain_Pilot_Addendum.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                Addendum
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/P2P_Energy_Trading_using_Blockchain_Pilot-Addendum-2.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                Addendum - 2
                              </a>
                            </li>
                          </ul>
                            </div>
                            ) : null}
                   </div>
                   
                   
                   */}
                                     
                   
                   </div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Tender;
